
import RenderPlacements from "../Components/RenderPlacements"
import { useEffect, useState } from "react"
export default function ManagePlacement() {

    return (
        <>
            <h1 className="text-center text-2xl font-semibold py-6">Manage Placements</h1>
            <RenderPlacements type='edit' />
        </>
    )

    
}