import { useEffect, useLayoutEffect } from "react"
import { useTestContext } from "../../../Providers/HATProvider";
import sections from "./sections";
import GlobalUtils from "../../../Utils/GlobalUtils";

export default function Mcq({ mcq }) {

    const { sectionID, pageID, setPageID, setSectionAndPage, setGlobalQuestions, resetSectionAndPage, setGlobalAnswers, globalQuestions } = useTestContext();

    const uidQuestion = GlobalUtils.uid10() + mcq.question

    function markAnswer(e) {
        const answerID = e.currentTarget.getAttribute('data-answer')
        const answerIndex = sections[sectionID].pages[pageID].questions.findIndex((question) => question.id == answerID)
        const answer = e.currentTarget.querySelector('input').value

        globalQuestions[sectionID].pages[pageID].questions[answerIndex].answer = answer
        setGlobalAnswers(globalQuestions)
    }


    const markedAnswer = mcq.answer


    return (

        <div className="mb-6 pr-6">
            <p className="mb-4 text-sm font-medium">Q{mcq.number != undefined ? mcq.number : ''}<span style={{ color: '' }}>{' )'}</span> {mcq.question}</p>
            {
                mcq.choices.map((choice, index) => {
                    const uid = GlobalUtils.uid10() + 'choice' + index; // Add index to ensure uniqueness

                    const checked = markedAnswer === choice ? true : false;

                    return (
                        <div key={uid} onClick={markAnswer} data-answer={mcq.id} className="w-fit flex mb-3">
                            <input className="pl-2 w-fit block my-auto" defaultChecked={checked} id={uid} type="radio" name={uidQuestion} value={choice} />
                            <label className="pl-2 text-sm font-medium my-auto block" htmlFor={uid}>{choice}</label>
                        </div>
                    );
                })

            }
            <div className="h-0.5 bg-gray-300 w-full mt-6">

            </div>

        </div >
    )

}