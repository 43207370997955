import React, { useState, useRef } from "react";
import "./hirefromus.css";
import gsap, { ScrollTrigger } from "gsap/all";
import Loader from "../../Global_Components/Loader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import CarouselComponent from "./CarouselComponent";
import IconCloudDemo from './IconCloudDemo';

const HireFromUs = () => {
  const [formStatus, setFormStatus] = useState("default");
  const [currentSlide, setCurrentSlide] = useState(0);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setFormStatus("loading");
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/forms/hirefromus`, values)
      .then((res) => {
        if (res.status == 200) {
          setFormStatus("submitted");
        } else setFormStatus("failed");
      });
  };

  gsap.registerPlugin(ScrollTrigger);
  gsap.to(".learn-quickly .ball-container", {
    scrollTrigger: {
      trigger: ".learn-quickly .blocks-container",
      scrub: true,
      start: "top 50%",
      end: "bottom 50%",
    },
    ease: "linear",
    top: "100%",
  });

  const accordionData = [
    {
      title: "What types of roles can I hire from HashPro Academy?",
      content:
        "We specialize in digital marketing roles, including SEO specialists, content marketers, social media managers, PPC experts, and more. Our graduates are trained in various aspects of digital marketing to meet your diverse needs.",
    },
    {
      title: "How are candidates selected for my requirements?",
      content:
        "Our team carefully reviews your hiring criteria and matches you with the best candidates from our pool of graduates. We consider factors such as skills, experience, and project work to ensure a perfect fit for your organization.",
    },
    {
      title: "What is the cost of hiring a candidate from HashPro Academy?",
      content:
        "The cost can vary depending on the specific requirements and duration of the role. Please get in touch with our team for detailed pricing information tailored to your needs.",
    },
    {
      title: "Can I hire candidates for remote positions?",
      content:
        "Absolutely! Our graduates are equipped to work remotely and are trained to use various digital tools and platforms, ensuring they can effectively contribute from any location.",
    },
    {
      title:
        "What support does HashPro Academy provide during the hiring process?",
      content:
        "We assist with everything from identifying suitable candidates to facilitating interviews and supporting the onboarding process. Our goal is to make hiring from us as seamless and efficient as possible.",
    },
    {
      title: "What makes HashPro Academy graduates stand out?",
      content:
        "Our graduates undergo rigorous training that combines theoretical knowledge with practical, hands-on experience. They are well-versed in the latest industry trends and best practices, ensuring they can deliver immediate value to your organization.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const accordionRefs = useRef([]);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      gsap.to(accordionRefs.current[index], {
        maxHeight: 0,
        duration: 0.5,
        ease: "power2.inOut",
      });
      setActiveIndex(null);
    } else {
      if (activeIndex !== null) {
        gsap.to(accordionRefs.current[activeIndex], {
          maxHeight: 0,
          duration: 0.5,
          ease: "power2.inOut",
        });
      }
      gsap.to(accordionRefs.current[index], {
        maxHeight: accordionRefs.current[index].scrollHeight,
        duration: 0.2,
        ease: "power2.inOut",
      });
      setActiveIndex(index);
    }
  };

  const slides = [
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png",
      title: "veeresh",
      name: "Morgan Sweeney",
      desc: "Lorem ipsum dolor sit, ar quod nulla? Distinctio rerum sunt libero temporibus aliquid saepe odit adipisci, veritatis atque nobis harum unde voluptate, iure, alias laudantium molestiae quidem? Mollitia, autem quisquam, id consequatur at",
      rating: 4,
    },
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png",
      title: "veeresh",
      name: "Morgan Sweeney",
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere voluptates quia sapiente earum cumque tenetur quibusdam fuga pariatur quod nulla? Distinctio rerum sunt libero temporibus aliquid saepe odit adipisci, veritatis atque nobis harum unde voluptate, iure, alias laudantium molestiae quidem? Mollitia, autem quisquam, id consequatur at",
      rating: 4,
    },
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png",
      title: "veeresh",
      name: "Morgan Sweeney",
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere voluptates quia sapiente earum cumque tenetur quibusdam fuga pariatur quod nulla? Distinctio rerum sunt libero temporibus aliquid saepe odit adipisci, veritatis atque nobis harum unde voluptate, iure, alias laudantium molestiae quidem? Mollitia, autem quisquam, id consequatur at",
      rating: 4,
    },
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png",
      title: "veeresh",
      name: "Morgan Sweeney",
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere voluptates quia sapiente earum cumque tenetur quibusdam fuga pariatur quod nulla? Distinctio rerum sunt libero temporibus aliquid saepe odit adipisci, veritatis atque nobis harum unde voluptate, iure, alias laudantium molestiae quidem? Mollitia, autem quisquam, id consequatur at",
      rating: 4,
    },
    {
      img: "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/480px-Google_%22G%22_logo.svg.png",
      title: "veeresh",
      name: "Morgan Sweeney",
      desc: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Facere voluptates quia sapiente earum cumque tenetur quibusdam fuga pariatur quod nulla? Distinctio rerum sunt libero temporibus aliquid saepe odit adipisci, veritatis atque nobis harum unde voluptate, iure, alias laudantium molestiae quidem? Mollitia, autem quisquam, id consequatur at",
      rating: 4,
    },
  ];

  const moveSlide = (direction) => {
    const totalSlides = slides.length;
    setCurrentSlide((currentSlide + direction + totalSlides) % totalSlides);
  };

  return (
    <div className="HireFromUs">
      <section className="HireFromUs-herosection">
        <div className="HireFromUs-herosection1">
          <h1>
            Hire Top Digital Marketing Talent from <span> HashPro</span>
          </h1>
          <p>
            Connect with skilled digital marketing professionals trained by
            industry experts.
          </p>
          <a href="#HireFromUs-form">
            <button>Start Hiring....</button>
          </a>
        </div>
        <div className="HireFromUs-herosection2">
          <img src="/global/students-life/images/cl-2.webp" alt="img" />
        </div>
      </section>
      <section id="HireFromUs-companySections">
        <h1>
          Empowering thousands of companies to recruit top-notch digital
          marketers
        </h1>
        <IconCloudDemo />
        <div>
          <div className="companySections company1"></div>
          <div className="companySections company2"></div>
          <div className="companySections company3"></div>
        </div>
      </section>

      <section
        style={{
          backgroundImage:
            "linear-gradient(#00000088,#00000088),url(/pages/dmx/images/journey-bg.png)",
          backgroundSize: "129rem",
        }}
        className="learn-quickly border-t-2 border-solid border-hashpro"
      >
        <div id="art-of-influence" />
        <h2 className="heading"> Hiring Process...🔢</h2>
        <div className="blocks-container">
          <div className="line-container"></div>
          <div className="ball-container" />
          <div className="block one">
            <div className="text">
              <h4 className="">📩 Get in Touch with Us </h4>
              <p>
                Reach out to us with your hiring needs through our online form
                or by contacting our team directly. Provide details such as the
                roles you're looking to fill, required skills, experience
                levels, and any other specific criteria.
              </p>
            </div>
            <div />
          </div>
          <div className="block two">
            <div />
            <div className="text">
              <h4 className="">🔍 Review Candidate Profiles</h4>
              <p>
                We will handpick candidates from our pool of skilled graduates
                who match your requirements. You will receive detailed profiles,
                including their resumes, project portfolios, and performance
                assessments.
              </p>
            </div>
          </div>
          <div className="block three">
            <div className="text">
              <h4 className="">✍ Conduct Interviews</h4>
              <p>
                Learning is fun when you do it practically. We believe in doing
                what you learn. Activities are designed to make you practically
                apply your knowledge.
              </p>
            </div>
            <div />
          </div>
          <div className="block four">
            <div />
            <div className="text">
              <h4 className="">🌟 Hire with Confidence</h4>
              <p>
                Once you've chosen the right fit, we assist with the onboarding
                process to ensure a seamless transition. Our graduates are ready
                to contribute from day one, equipped with the latest industry
                knowledge and practical experience.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="HireFromUs-faqs">
        <h1 className="text-center pb-4">FAQs</h1>
        <div className="accordion">
          {accordionData.map((item, index) => (
            <div key={index} className="accordion-group">
              <div
                className="accordion-menu"
                onClick={() => toggleAccordion(index)}
              >
                {item.title}
                <div
                  className={`accordion-plus ${
                    activeIndex === index ? "hidden" : ""
                  }`}
                >
                  +
                </div>
                <div
                  className={`accordion-minus ${
                    activeIndex !== index ? "hidden" : ""
                  }`}
                  onClick={() => toggleAccordion(index)}
                >
                  -
                </div>
              </div>
              <div
                ref={(el) => (accordionRefs.current[index] = el)}
                className={`accordion-content ${
                  activeIndex === index ? "expanded" : ""
                }`}
              >
                <p>{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section className="HireFromUs-form" id="HireFromUs-form">
        <h1>connect with us</h1>
        <div
          className="lead-form w-full"
          style={{ height: "fit-content", margin: "0 auto" }}
          id="lead-form"
        >
          <p className="text-lg text-black font-semibold mt-2">
            Get in Touch With Our Team
          </p>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form
                id="contact-form"
                className="dark inputs-container flex flex-col gap-y-4"
              >
                <div>
                  <Field
                    id="name"
                    className="border-black"
                    placeholder="Enter Your Name"
                    type="text"
                    name="name"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div>
                  <Field
                    id="email"
                    placeholder="Enter Your Email"
                    type="email"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>
                <div>
                  <Field
                    id="phone"
                    placeholder="Enter Your Phone No. "
                    type="tel"
                    name="phone"
                    
                  />
                  <span className=" text-yellow-500 p-2 m-2">
                    {" "}
                    *Preferably WhatsApp Number
                  </span>
                  <ErrorMessage
                    name="phone"
                    component="div"
                    style={{ color: "red" }}
                  />
                </div>

                <div className="center">
                  <button
                    type="submit"
                    className="black submit-btn w-full"
                    disabled={
                      isSubmitting ||
                      formStatus == "loading" ||
                      formStatus == "submitted"
                    }
                  >
                    {formStatus === "loading" ? (
                      <Loader />
                    ) : formStatus === "failed" ? (
                      "Failed, Submit Again"
                    ) : formStatus === "submitted" ? (
                      "Form Has Been Submitted, Our Team May Get In Touch With You Soon"
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>

                <ErrorMessage
                  name="general"
                  component="div"
                  style={{ color: "red" }}
                />
              </Form>
            )}
          </Formik>
        </div>
      </section>

      <CarouselComponent />

      <h2 className="heading mt-20"> Hear from our Hiring Partners</h2>
      <section className="HireFromUs-hiringPartners">
        <div className="slide-container">
        {slides.map((slide, index) => (
          <div
            className="recruter-card"
            style={{ transform: `translateX(${-currentSlide * 100}%)` }}
            key={index}
          >
            <div className="img-avatar">
              <img src={slide.img} alt="" />
            </div>
            <div className="recruitercard-text">
              <div className="portada"></div>
              <div className="title-total">
                <div className="title">{slide.title}</div>
                <h2>{slide.name}</h2>
                <div className="desc">{slide.desc}</div>
                <div>
                  {Array.from({ length: slide.rating }, (_, i) => (
                    <span key={i}>⭐</span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
        </div>
        <div className="hirefrom-us-slides-control">
        <button className="nav-button left" onClick={() => moveSlide(-1)}>
          &#10094;
        </button>
        <button className="nav-button right" onClick={() => moveSlide(1)}>
          &#10095;
        </button>
        </div>
        
      </section>
      
    </div>
  );
};

export default HireFromUs;
