import { db } from "..";
import { doc, getDoc } from "firebase/firestore";

const MasterClassUtils = {

    getSeatsFromDate: async (bookingDate) => {

        const seatsRef = doc(db, "DMXMasterClass", bookingDate);

        const fetchedSeatsDoc = await getDoc(seatsRef);

        let seats = {}

        if (!fetchedSeatsDoc.exists()) {

            console.log('No such document!');

            seats.morningFilled = 0
            seats.eveningFilled = 0

        } else {

            console.log('Booking Date in Utils', bookingDate)

            const firestoreData = fetchedSeatsDoc.data()

            const capacity = firestoreData.capacity

            const morningSeatsFilled = (firestoreData.morningFilled != null || firestoreData.morningFilled != undefined) ? firestoreData.morningFilled : 0
            const eveningSeatsFilled = (firestoreData.eveningFilled != null || firestoreData.eveningFilled != undefined) ? firestoreData.eveningFilled : 0

            const percentageMorningFilled = (morningSeatsFilled / capacity) * 100;
            const percentageEveningFilled = (eveningSeatsFilled / capacity) * 100;

            seats.morningFilled = percentageMorningFilled
            seats.eveningFilled = percentageEveningFilled

            console.log('Document data:', morningSeatsFilled, eveningSeatsFilled, capacity);

        }

        return seats

    }

}


export default MasterClassUtils