import LocationWhite from './icons/location-white.png'
import EmailWhite from './icons/email-white.png'
import PhoneWhite from './icons/phone-white.png'
import HashproLogo from './logos/hashpro-white-notagline.png'
import FacebookWhite from './icons/facebook.png'
import InstagramWhite from './icons/instagram.png'
import LinkedinWhite from './icons/linkedin.png'
import YoutubeWhite from './icons/youtube.png'
import TwitterWhite from './icons/twitter.png'
import WWW from './icons/www.png'
import DeleteBlack from './icons/delete-black.png'
import EditBlack from './icons/edit-black.png'
import CursorBlack from './icons/cursor-black.png'


export const icons = {
    LocationWhite,
    EmailWhite,
    PhoneWhite,
    HashproLogo,
    FacebookWhite,
    InstagramWhite,
    LinkedinWhite,
    YoutubeWhite,
    TwitterWhite,
    WWW,
    DeleteBlack,
    EditBlack,
    CursorBlack
}



