import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const NumberCounterDecimal = ({ targetNumber }) => {
    const [count, setCount] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const { ref, inView } = useInView({ triggerOnce: true });

    useEffect(() => {
        if (inView) {
            setIsVisible(true);
        }
    }, [inView]);

    useEffect(() => {
        let start = 0;
        const end = targetNumber;
        const duration = 1000; // Change as needed
        const steps = Math.abs(end - start) * 10; // Consider each step for 0.1 increments
        const stepTime = duration / steps;

        if (isVisible) {
            let currentStep = start;

            const timer = setInterval(() => {
                currentStep += 0.1; // Increment by 0.1 for decimal precision
                setCount(currentStep.toFixed(1)); // Display with 1 decimal place
                if (currentStep >= end) {
                    setCount(end.toFixed(1)); // Ensure the end value is precise
                    clearInterval(timer);
                }
            }, stepTime);
            return () => clearInterval(timer);
        }
    }, [isVisible, targetNumber]);

    return (
        <div className='inline-block' ref={ref}>
            <span>{count}</span>
        </div>
    );
};

export default NumberCounterDecimal;
