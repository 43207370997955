import React from 'react'
import Signin from '../Placement/Signin'

import { useNavigate } from 'react-router-dom'

const Paynow = () => {
    const navigate =useNavigate()
  return (
    <section className='paynow-sec'>
        <div className='container'>
            <p>You Have't Logged in Yet</p>
            <button onClick={()=>{
                navigate("/sign-in")
            }}>click here to sign in....</button>
        </div>
        
    </section>
  )
}

export default Paynow