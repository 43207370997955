import { Helmet } from 'react-helmet'
import './students-life.css'
export default function StudentsLife() {

    return (
        <>
            <Helmet>
                <title>{'Student\'s Life'}</title>
                <meta name="description" content="Student\'s Life" />
                <meta property="og:title" content="Your Open Graph Title" />
                <meta property="og:description" content="Your Open Graph Description" />
                <meta property="og:image" content="URL to Your Image" />
                <meta property="og:url" content="URL to Your Web Page" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Your Site Name" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:title" content="Your Twitter Title" />
                <meta name="twitter:description" content="Your Twitter Description" />
                <meta name="twitter:image" content="URL to Your Image" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className="class-hero">
                <div className="grid">
                    <div></div>
                    <h1 className="heading">
                        <span className="yellow-text"> Learn.</span>{" "}
                        <span className="yellow-text"> Grow.</span>{" "}
                        <span className="yellow-text"> Thrive.</span>
                        <br /> It all starts here
                    </h1>
                </div>
            </section>
            <section className="class">
                <div className="first">
                    <img src="/global/students-life/images/g-2.webp" alt="" />
                </div>

                <div className="third">

                    <img src="/global/students-life/images/g-3.webp" alt="" />

                </div>
                <div className="fourth">
                    <img src="/global/students-life/images/g-1.webp" alt="" />

                </div>
            </section>
            <section className="experiential-hero ">
                <div className="grid">
                    <h1 className="heading">
                        Where <span className="yellow-text"> passion</span> meets{" "}
                        <span className="yellow-text"> education</span>. Buckle up!{" "}
                    </h1>
                    <div></div>
                </div>
            </section>
            <section className="experiential cl">
                <div className="first">
                    <img src="/global/students-life/images/cl-1.webp" alt="" />
                </div>
                <div className="second">
                    <img src="/global/students-life/images/cl-2.webp" alt="" />
                </div>
                <div className="empty" />
                <div className="third">
                    <img src="/global/students-life/images/cl-3.webp" alt="" />
                </div>
                <div className="fourth">
                    <img src="/global/students-life/images/cl-4.webp" alt="" />
                </div>
            </section>
            {/* <section className="experiential-hero">
                <div className="grid">
                    <div></div>
                </div>
            </section> */}
            <section className="experiential iv">

                <div className="second">
                    <img src="/global/students-life/images/iv-2.jpg" alt="" />
                </div>

                <div className="text">
                    <h1 className="heading">
                        Beyond textbooks, experience the{" "}
                        <span className="yellow-text"> real world</span>
                        <br />

                    </h1>
                    <p className='text-xl mt text-right mt-2 '>
                        ( Gain hands-on experience with exclusive industry visits )
                    </p>
                </div>
                <div className="third">
                    <img src="/global/students-life/images/iv-3.jpg" alt="" />
                </div>

                <div className="fifth">
                    <img src="/global/students-life/images/iv-5.webp" alt="" />
                </div>
            </section>
            {/* <section className="experiential-hero">
                <div className="grid">
                    <div></div>
                </div>
            </section> */}
            <section className="experiential gl">
                <div className="first">
                    <img src="/global/students-life/images/gl-1.webp" alt="" />
                </div>
                <div className="second">
                    <img src="/global/students-life/images/gl-2.webp" alt="" />
                </div>
                <div className="empty">
                    <h1 className="heading">
                        Learn from the best. Network with the{" "}
                        <span className="yellow-text"> experts</span>.
                    </h1>
                </div>
                <div className="third">
                    <img src="/global/students-life/images/gl-3.webp" alt="" />
                </div>
                <div className="fourth">
                    <img src="/global/students-life/images/gl-4.webp" alt="" />
                </div>
            </section>
            <br />
            <br />
            <br />
            <br />
            <br />


        </>


    )
}