import Calendar from 'react-calendar'
import './css/react-calendar-styles.css'
import 'react-calendar/dist/Calendar.css';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useMasterClass } from '../Providers/MasterclassProvider';
import GlobalUtils from '../Utils/GlobalUtils';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '..';
import MasterClassUtils from '../Utils/MasterClassUtils';

export default function SaturdayCalendar() {

    // const disabledDates = [new Date(2024, 6, 13),new Date(2024, 7, 10)]
    const disabledDates = []

    const MasterClassOptions = useMasterClass()

    const [value, onChange] = useState();
    const [fetchingSeats, setFetchingSeats] = useState(true)

    const [fastSellingDates, setFastSellingDates] = useState([])
    const [filledDates, setFilledDates] = useState([])
    const [availableDates, setAvailableDates] = useState([])

    const today = new Date()

    const isSaturday = (date) => date.getDay() === 6;
    const getUpcomingSaturday = (date) => {
        const day = date.getDay();
        const diff = day === 6 ? 0 : 6 - day;
        return new Date(date.setDate(date.getDate() + diff));
    };

    const upcomingSaturday = getUpcomingSaturday(new Date(today));

    const tileDisabled = ({ date, view }) => {
        // Disable all non-Saturdays
        if (!isSaturday(date)) {
            return true;
        }

        // Disable the current date if it is not a Saturday
        if (date.toDateString() === today.toDateString() && !isSaturday(today)) {
            return true;
        }

        // Disable all previous dates from the current or upcoming Saturday except the coming Saturday
        if (date < upcomingSaturday && date.toDateString() !== upcomingSaturday.toDateString()) {
            return true;
        }

        for (const toDisable of disabledDates) {
            if (date.toDateString() == toDisable.toDateString())
                return true
        }

        return false;
    };  

    // Update Saturdays when date changes
    useEffect(() => {

        let date = MasterClassOptions.masterClassDate

        if (date == '') {
            date = new Date()
        }

    }, [MasterClassOptions.masterClassDate]);


    function handleCalendarViewChange({ activeStartDate }) {

        setFetchingSeats(true)
        const saturdays = getSaturdaysInMonthFromDate(activeStartDate)
        console.log('All Saturdays', saturdays)
        fetchAllSeats(saturdays)

    }

    async function fetchAllSeats(saturdays) {

        let seatsDataForMonth = []

        for (const saturday of saturdays) {
            console.log('Fetchiong Date:', saturday)

            let skipDate = false
            for (const toDisable of disabledDates) {
                if (toDisable.toDateString() == saturday.toDateString()) {
                    skipDate = true
                    break
                }
            }

            if (skipDate) continue

            const savedDates = MasterClassOptions.masterClassSeats
            console.log(savedDates, 'Is Date Saved :', MasterClassOptions.masterClassSeats.includes(saturday))

            let fetchDate = true

            for (const date of savedDates) {
                if (date.date == GlobalUtils.convertToDDMMYYYY(saturday)) {
                    fetchDate = false
                }
            }

            if (!fetchDate) continue

            const completeDate = GlobalUtils.convertToDDMMYYYY(saturday)

            const seats = await MasterClassUtils.getSeatsFromDate(completeDate)

            if (seats.eveningFilled == 100 && seats.morningFilled == 100) {

                setFilledDates(filledDates => [...filledDates, saturday])
                console.log('setFilledDates', seats)

            } else if (seats.eveningFilled > 80 || seats.morningFilled > 80) {

                setFastSellingDates(fastSellingDates => [...fastSellingDates, saturday])
                console.log('setFastSellingDates', seats)

            } else {

                setAvailableDates(availableDates => [...availableDates, saturday])
                console.log('setAvailableDates', seats)

            }

            seats.date = GlobalUtils.convertToDDMMYYYY(saturday)

            seatsDataForMonth.push(seats)

            const masterclassseats = MasterClassOptions.masterClassSeats
            MasterClassOptions.setMasterClassSeats(masterclassseats => [...masterclassseats, seats])

        }

        setFetchingSeats(false)

    }

    function getSaturdaysInMonthFromDate(date) {
        const saturdays = [];
        const year = date.getFullYear();
        const month = date.getMonth();
        const today = new Date();
        const tempDate = new Date(year, month, 1);

        // Check if the current date is a Saturday
        if (today.getDay() === 6 && today.getFullYear() === year && today.getMonth() === month) {
            saturdays.push(new Date(today));
        }

        // Iterate through the days of the month
        while (tempDate.getMonth() === month) {
            if (tempDate.getDay() === 6 && tempDate > today) { // Check if it's a Saturday and in the future
                saturdays.push(new Date(tempDate));
            }
            tempDate.setDate(tempDate.getDate() + 1);
        }

        return saturdays;
    }


    // Usage example
    const date = new Date(); // Or any specific date
    const futureSaturdays = getSaturdaysInMonthFromDate(date);
    console.log(futureSaturdays);


    useEffect(() => {
        //for getting seats initially
        handleCalendarViewChange({ activeStartDate: new Date() })
    }, [])


    const hasFastSellingClass = (date) => {
        return fastSellingDates.some(d => d.toISOString().split('T')[0] === date.toISOString().split('T')[0]);
    };

    const hasAvailableClass = (date) => {
        return availableDates.some(d => d.toISOString().split('T')[0] === date.toISOString().split('T')[0]);
    };

    const hasFilledClass = (date) => {
        return filledDates.some(d => d.toISOString().split('T')[0] === date.toISOString().split('T')[0]);
    };

    const statusTileContent = ({ date, view }) => {

        if (view === 'month') {
            const dateNumber = date.getDate();

            if (hasFastSellingClass(date)) {
                console.log('hasFastSellingClass')
                return <div className="fast-selling saturday-masterclass"><p>{dateNumber}</p></div>;
            } else if (hasAvailableClass(date)) {
                return <div className="available-class saturday-masterclass"><p>{dateNumber}</p></div>;
            } else if (hasFilledClass(date)) {
                return <div className="filled-class saturday-masterclass"><p>{dateNumber}</p></div>;
            }
        }

        return null
    };

    return (
        <>
            <div className=' p-0 py-2 bg-[white] w-[90%]  m-auto h-fit rounded-lg border border-black border-solid shadow-[2px_2px_#f8d00d] sm:p-2'>

                <Calendar
                    value={MasterClassOptions.masterClassDate}
                    onChange={MasterClassOptions.setMasterClassDate}
                    calendarType="hebrew"
                    tileDisabled={tileDisabled}
                    showNeighboringMonth={false}
                    onActiveStartDateChange={handleCalendarViewChange}
                    tileContent={statusTileContent}
                />

                <div className="mt-3">

                    {
                        !fetchingSeats ?
                            <div className='flex flex-wrap gap-3 w-fit mx-auto '>
                                <div className='flex gap-1'>
                                    <div className="w-2 h-2 m-auto bg-red-600 rounded-[50%]"></div>
                                    <p className='text-[black] text-xs font-semibold'>Seats Filled</p>
                                </div>
                                <div className='flex gap-1'>
                                    <div className="w-2 h-2 m-auto bg-yellow-400 rounded-[50%]"></div>
                                    <p className='text-[black] text-xs font-semibold'>Fast Filling</p>
                                </div>
                                <div className='flex gap-1'>
                                    <div className="w-2 h-2 m-auto bg-emerald-600 rounded-[50%]"></div>
                                    <p className='text-[black] text-xs font-semibold'>Seats Available</p>
                                </div>
                            </div>
                            :
                            <p className='text-xs font-semibold text-black text-center '>Fetching Available Seats</p>
                    }

                </div>

            </div>

        </>
    )

}