const pathElements = document.querySelectorAll('span.animated path');

function addAnimateClass() {
    pathElements.forEach(function (element) {
        const boundingBox = element.getBoundingClientRect();
        const innerWindowHeight = window.innerHeight || document.documentElement.clientHeight;

        if (boundingBox.top >= 0 && boundingBox.bottom <= innerWindowHeight * 0.8) {
            element.classList.add('animate');
        }
    });
}

function checkElements() {
    addAnimateClass();
}

window.addEventListener('scroll', checkElements);

checkElements();
