import axios from "axios";
import firebase from "firebase/app";
import "firebase/auth";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { notifyError, notifySuccess } from "./Toast";

const UserUtils = {

    getEmail: () => {
        const auth = getAuth();
        const user = auth.currentUser;
        return user.email
    },
    isUserLoggedIn: () => {

        const auth = getAuth();

        return new Promise((resolve, reject) => {
            const unsubscribe = onAuthStateChanged(auth, (user) => {
                if (user != null) {
                    // User is logged in
                    console.log("User is logged in:", user.email);
                    unsubscribe(); // Stop listening for further changes
                    resolve(true);
                } else {
                    // No user is logged in
                    console.log("No user is logged in");
                    unsubscribe(); // Stop listening for further changes
                    resolve(false);
                }
            }, (error) => {
                // An error occurred
                console.error("Error checking user login status:", error);
                reject(error);
            });
        });
    },
    signOut: () => {
        const auth = getAuth()
        auth.signOut().then(function () {
            console.log('Logged Out')
            window.location.replace('/')
        }).catch(function (error) {
            console.log('Error Logging Out')
        });
    },
    newUser: async (values) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/hat/new-user`,
                values,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            console.log(response)

            localStorage.setItem("timerSubmit", false)
            document.cookie = `userid=${response.data}`;
            notifySuccess('Login Successful')

            return true

        } catch (error) {
            console.error('Error:', error.response.status);
            const errCode = error.response.status
            if (errCode == 600) {
                console.log('Email Already In Use')
                notifyError('Email Already Used For HAT')
            }
            return false
        }

    },
    getHatUserId: () => {
        const userID = getCookie('userid')
        return userID
    }
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default UserUtils