import { icons } from "./Icons"
import { logos } from "./Logos"
import './footer.css'
import { Link, useLocation } from "react-router-dom"
export default function Footer() {

    const location = useLocation()


    return (

        <>
            {!location.pathname.startsWith('/hat') &&
                <footer>
                    <img
                        alt="hashpro-logo"
                        loading="lazy"
                        className="logo"
                        src="/global/logos/hashpro-white.png"
                    />
                    <div className="divider" />
                    <div className="grid gap-8  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-12 px-12">

                        <div className="footer-social-media">
                            <h4 className="text-hashpro text-left mb-3 font-medium">Socials</h4>
                            <div className="flex gap-8">
                                <a href="https://www.instagram.com/thehashproacademy/" target="_blank">
                                    <img
                                        alt="instagram-logo"
                                        loading="lazy"
                                        width="30px"
                                        src="/global/icons/instagram.webp"
                                    />
                                </a>
                                <a
                                    href="https://www.linkedin.com/company/hashproacademy/"
                                    target="_blank"
                                >
                                    <img
                                        alt="linkedin-logo"
                                        loading="lazy"
                                        width="30px"
                                        src="/global/icons/linkedin.webp"
                                    />
                                </a>
                                <a href="https://www.youtube.com/@hashproacademy" target="_blank">
                                    <img
                                        alt="youtube-logo"
                                        loading="lazy"
                                        width="30px"
                                        src="/global/icons/youtube.webp"
                                    />
                                </a>
                                <a href="https://twitter.com/Hashproacademy" target="_blank">
                                    <img
                                        alt="twitter-logo"
                                        loading="lazy"
                                        width="30px"
                                        src="/global/icons/twitter.webp"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="footer-courses">
                            <div className="flex flex-col gap-4">
                                <div>
                                    <h4 className="text-hashpro text-left mb-3 font-medium">Our Programs</h4>

                                    <div className="flex flex-col gap-2">
                                        {/* <a href="/digital-marketing" className="course-name">
                                Digital Marketing
                            </a> */}
                                        <a href="/art-of-influence" className="course-name">
                                            Art of Influence
                                        </a>
                                        <a href="/digital-marketing-xperential-bootcamp" className="course-name">
                                            Digital Marketing Xperential Bootcamp
                                        </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="footer-courses">

                            <h4 className="text-hashpro text-left mb-3 font-medium">Explore</h4>
                            <div className="flex flex-col gap-2">
                                <a href="/students-life" className="course-name">
                                    Student's Life
                                </a>
                                <a href="/careers/business-development" className="course-name">
                                    Recruit Talent
                                </a>
                                <a href="/contact" className="course-name">
                                    Contact
                                </a>
                                <a href="/about" className="course-name">
                                    About
                                </a>
                            </div>

                        </div>
                        <div>
                            <h4 className="text-hashpro text-left mb-3 font-medium">Policies</h4>
                            <div className="flex flex-col gap-2 text-left">
                                <a className="policy" href="/terms-and-conditions ">
                                    Terms &amp; Conditions
                                </a>
                                <a className="policy" href="/cancellation-and-refund-policy ">
                                    Cancellation &amp; Refund Policy
                                </a>
                                <a className="policy" href="/shipping-and-delivery-policy ">
                                    Shipping &amp; Delivery Policy
                                </a>
                                <a className="policy" href="/privacy-policy ">
                                    Privacy Policy
                                </a>
                            </div>
                        </div>
                        <div className="company-name">
                            <h4 className="text-hashpro text-left mb-3 font-medium">Address</h4>

                            <h3 className="text-left ">
                                <a className="text-[#aaaaaa] hover:text-white font-light" target="_blank" href="https://maps.app.goo.gl/w2RHzaZnmdtece4v8">
                                    3rd Floor, Barwale Chambers, Street Number 11, Himayatnagar,
                                    Hyderabad, Telangana 500029
                                </a>
                            </h3>
                        </div>
                    </div>



                    <div className="policy-container">

                    </div>
                    <div className="py-4">
                        <div className="company-name">
                            <h3 className="text-center">
                                © Procave EdTech Private Limited 2023
                                <br />
                                All Rights Reserved Hashpro Academy
                                <br />
                                <br />
                                GSTN - 36AANCP6168L1ZN

                            </h3>
                        </div>
                    </div>
                </footer>

            }



        </>


    )
}