import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import Header from "../../Global_Components/Header";
import { useEffect, useState } from "react";
import UserUtils from "../../Utils/UserUtils";
import { AuthProvider, useAuth } from "../../Providers/AuthProvider";
import { db } from "../..";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";


export default function HDFCSignup() {
    return (
        <AuthProvider>
            <HDFC />
        </AuthProvider>
    )
}


function HDFC() {

    const [isLoggedIn, setLoggedIn] = useState(false)
    const [userMail, setUserMail] = useState('')
    const { currentUser, signIn, signOut } = useAuth();


    useEffect(() => {
        console.log(currentUser)

    }, [currentUser])

    const provider = new GoogleAuthProvider();

    const auth = getAuth();

    function signInAdmin() {

        signInWithPopup(auth, provider)
            .then(async (result) => {

                const user = result.user;

                await addDoc(collection(db, "Admins"), {
                    email: user.email,
                    joinDate: serverTimestamp(),
                    access: true

                }).then(() => {
                    console.log('Admin Email Updated')
                    return true

                }).catch((e) => {
                    alert('Cannot add Mails, Please Try Again')
                });

                await addDoc(collection(db, "Students"), {
                    email: user.email,
                    joinDate: serverTimestamp(),
                    access: true

                }).then(() => {
                    console.log('Admin Email Updated')
                    return true

                }).catch((e) => {
                    alert('Cannot add Mails, Please Try Again')
                });


                window.location.href = '/user'
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                // IdP data available using getAdditionalUserInfo(result)
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;

                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
            });

    }

    const customStyle = {
        backgroundColor: 'white',
        color: 'black',
        fontWeight: 'bold',
    };

    return (
        <>
            <Header />
            <div className="relative h-[100vh] w-[100dvw] bg-cross-grid">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 m-auto p-4 py-12 bg-hashpro sm:rounded-xl text-black rounded-xl w-fit h-fit">
                    <div className="w-fit sm:ml-auto my-auto h-fit px-6">

                        <h1 className="text-3xl font-semibold">Admin Signup PG</h1>
                        <h2 className="text-xs">*Only for Payment Gateway</h2>
                        <br />
                        <div>
                            {
                                currentUser == null ?
                                    <>
                                        <button onClick={signInAdmin} variant="contained" className="mt-8 black" >Sign In With Google</button>
                                    </>
                                    :
                                    <>
                                        <h1 className="text-md font-semibold">Already Signed in as <span className="bg-[#131313] font-light text-sm text-white px-3 py-1 rounded-2xl my-auto">  {currentUser.email}</span></h1>
                                        <div className="flex h-fit mt-6 gap-3">
                                            <button onClick={() => UserUtils.signOut()} className=" bg-white h-fit">Sign Out</button>

                                            <a className="text-black block" href='/user'>
                                                <button className=" bg-white">User Dashboard</button>
                                            </a>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}