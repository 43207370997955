const sections = [

    {
        id: '',
        type: '',
        name: 'English',
        cutoff: '5',
        pages: [
            {
                type: 'plain',
                questions: [{
                    'number': '1',

                    'id': 'oneassddsfdas',
                    'question': '___________? Adolf Hitler did.',
                    'choices': [
                        'Who start world war II',
                        'Who did world war II',
                        'Who cause the world war II',
                        'Who started the second world war'
                    ],
                    answer: ''
                }]
            },
            {
                type: 'paragraph',
                paragraph: ["The first thing which a scholar should bear in mind is that a book ought not to be read for mere amusement. Half-educated persons read for amusement, and are not to be blamed for it, they are incapable of appreciating the deeper qualities that belong to a really great literature. But a young man who has passed through a course of University training should discipline himself at an early day never to read for mere amusement.", "And once the habit of discipline has been formed, he will find it impossible to read for mere amusement. He will then impatiently throw down any book from which he cannot obtain intellectual food, any book which does not make an appeal to the higher emotions and to his intellect.", "But on the other, the habit of reading for amusement becomes with thousands of people exactly the same kind of habit as wine-drinking or opium-smoking, it is like a narcotic, something that keeps up a perpetual condition of dreaming, something that eventually results in destroying all capacity for thought, giving exercise only to the surface parts of the mind and leaving the deeper springs of feelings and the higher faculties of perception unemployed."],
                questions: [{
                    'number': '2',

                    'id': 'one',
                    'question': 'What does the passage suggest about the consequences of reading for mere amusement, especially for half-educated persons?',
                    'choices': [
                        'It enhances their capacity for deeper thought.',
                        'It elevates ones intellectual development.',
                        'It can lead to a perpetual condition of dreaming.',
                        'It makes them appreciate great literature more.'
                    ],
                    answer: ''
                }, {
                    'number': '3',

                    'id': 'two',
                    'question': 'What qualities does the passage suggest scholars should seek in literature, as opposed to mere amusement?',
                    'choices': [
                        'Superficial emotions and light entertainment.',
                        'Intellectual her emofood and higtions.',
                        'Narcotic effects and perpetual dreaming.',
                        'Mere amusement and relaxation.'
                    ],
                    answer: ''
                },
                {
                    'number': '4',

                    'id': 'bfdsfvdsa',
                    'question': 'How does the passage characterize the habit of reading for amusement in individuals who have undergone a course of University training?',
                    'choices': [
                        'It is essential for intellectual growth.',
                        'It is acceptable in moderation.',
                        'It is akin to a narcotic, destructive to deep thinking.',
                        'It is encouraged for surface-level mental exercise.'
                    ],
                    answer: ''
                }
                ]
            },
            {
                type: 'paragraph',
                paragraph: ["The first thing which a scholar should bear in mind is that a book ought not to be read for mere amusement. Half-educated persons read for amusement, and are not to be blamed for it, they are incapable of appreciating the deeper qualities that belong to a really great literature. But a young man who has passed through a course of University training should discipline himself at an early day never to read for mere amusement.", "And once the habit of discipline has been formed, he will find it impossible to read for mere amusement. He will then impatiently throw down any book from which he cannot obtain intellectual food, any book which does not make an appeal to the higher emotions and to his intellect.", "But on the other, the habit of reading for amusement becomes with thousands of people exactly the same kind of habit as wine-drinking or opium-smoking, it is like a narcotic, something that keeps up a perpetual condition of dreaming, something that eventually results in destroying all capacity for thought, giving exercise only to the surface parts of the mind and leaving the deeper springs of feelings and the higher faculties of perception unemployed."],
                questions: [{
                    'number': '5',

                    'id': 'bfdsfvdasdsadsa',
                    'question': 'Identify the correct sentence:',
                    'choices': [
                        'He and I went to the store',
                        'Me and him went to the store',
                        'Who cause the world war II',
                        'Who did world war II'
                    ],
                    answer: ''
                }]
            },
            {
                type: 'paragraph',
                paragraph: ["<b>Para 1:</b> The city of Teotihuacán, which lay about 50 kilometers northeast of modern-day Mexico City, began its growth by 200-100 B.C. At its height, between about A.D. 150 and 700, it probably had a population of more than 125,000 people and covered at least 20 square kilometers. It had over 2,000 apartment complexes, a great market, a large number of industrial workshops, an administrative center, a number of massive religious edifices, and a regular grid pattern of streets and buildings. Clearly, much planning and central control were involved in the expansion and ordering of this great metropolis. Moreover, the city had economic and perhaps religious contacts with most parts of Mesoamerica (modern Central America and Mexico).", "<b>Para 2:</b> How did this tremendous development take place, and why did it happen in the Teotihuacán Valley? Among the main factors are Teotihuacán’s geographic location on a natural trade route to the south and east of the Valley of Mexico, the obsidian1 resources in the Teotihuacán Valley itself, and the valley’s potential for extensive irrigation. The exact role of other factors is much more difficult to pinpoint—for instance, Teotihuacán’s religious significance as a shrine, the historical situation in and around the Valley of Mexico toward the end of the first millennium B.C., the ingenuity and foresightedness of Teotihuacán’s elite, and, finally, the impact of natural disasters, such as the volcanic eruptions of the late first millennium B.C."],
                questions: [{
                    'number': '6',

                    'id': 'zgsefz',
                    'question': 'In paragraph 1, each of the following is mentioned as a feature of the city of Teotihuacán between A.D. 150 and 700 EXCEPT',
                    'choices': [
                        'regularly arranged streets',
                        'several administrative centers spread across the city',
                        'many manufacturing workshops',
                        'apartment complexes'
                    ],
                    answer: ''
                },
                {
                    'number': '7',

                    'id': 'csadc',
                    'question': 'According to the paragraph 1, what were some key features of Teotihuacán at its height?',
                    'choices': [
                        'A population of 125,000 and a grid pattern of streets.',
                        'Over 2,000 apartment complexes and a regular market.',
                        'Limited industrial workshops and religious edifices.',
                        'Economic contacts with regions outside of Mesoamerica.'
                    ],
                    answer: ''
                },
                {
                    'number': '8',

                    'id': 'dsada',
                    'question': 'The word “ingenuity” in paragraph 2 is closest in meaning to',
                    'choices': [
                        'ambition',
                        'sincerity',
                        'faith',
                        'cleverness'
                    ],
                    answer: ''
                },
                {
                    'number': '9',

                    'id': 'evgsfe',
                    'question': 'Which of the following is NOT mentioned in paragraph 2 as a main factor in the development of Teotihuacán?',
                    'choices': [
                        'The presence of obsidian in the Teotihuacán Valley',
                        'The potential for extensive irrigation of Teotihuacán Valley lands',
                        'A long period of volcanic inactivity in the Teotihuacán Valley',
                        ' Teotihuacán’s location on a natural trade route'
                    ],
                    answer: ''
                }]
            }]

    },

    {
        id: '',
        type: '',
        name: 'Mathematics',
        cutoff: '5',

        pages: [
            {
                type: 'plain',
                questions: [{
                    'number': '10',
                    'id': 'threeasecasd',
                    'question': 'Ravi purchases a sweater priced at ₹600 and applies a 15% discount coupon. Additionally, he incurs a 12% tax on the discounted price. What is the total amount Ravi pays?',
                    'choices': [
                        '₹582.0',
                        '₹571.2',
                        '₹510.3',
                        '₹528.0'
                    ],
                    answer: ''
                }, {
                    'number': '11',
                    'id': 'four',
                    'question': 'Karen is 6 years older than her sister Michelle, and Michelle is 2 years younger than her brother David. If the sum of their ages is 62, how old is Michelle?',
                    'choices': [
                        '18',
                        '20',
                        '16',
                        '24'
                    ],
                    answer: ''
                }]

            }
            , {
                type: 'math',
                imgURL: '/images/hat/chart-1.png',
                questions: [{
                    'id': 'three',
                    'number': '12',

                    'question': 'What is the difference between the total number of employees in the teaching and medical professions together and the number of employees in the management profession?',
                    'choices': [
                        '14,472',
                        '11,256',
                        '6,700',
                        '7,200'
                    ],
                    answer: ''
                }, {
                    'id': 'four',
                    'number': '13',

                    'question': 'In the management profession three-fourth of the number of employees are female. What is the number of male employees in the management profession?',
                    'choices': [
                        '1,139',
                        '2,341',
                        '20,100',
                        '3,417'
                    ],
                    answer: ''
                },
                {
                    'id': 'foasdsaasdur',
                    'number': '14',

                    'question': '25% of employees from the film production profession went on a strike. What is the number of employees from film production who have not participated in the strike?',
                    'choices': [
                        '6,700',
                        '3,819',
                        '2,100',
                        '4,800'
                    ],
                    answer: ''
                }
                ]


            },
            {
                type: 'plain',
                questions: [
                    {
                        'id': 'foudrawrzr',
                        'number': '15',

                        'question': 'Leela has $240. She spends one-fourth of it on clothes and then donates $60 to charity. How much money does she have left?',
                        'choices': [
                            '100',
                            '120',
                            '140',
                            '160'
                        ],
                        answer: ''
                    },
                    {
                        'id': 'fousaevdsadsadrawrzr',
                        'number': '16',

                        'question': "A gaming company charges Rs. 49 for it's game \"Windrunner\" on the Android play store. The total cost of maintaining the game and bug fixing comes to Rs. 8000 a month. Let 'n' denote the number of copies sold per month. Which of the following inequalities must 'n' satisfy for the company to make profit?",
                        'choices': [
                            'n>164',
                            'n<80',
                            'n>50',
                            'n<100'
                        ],
                        answer: ''
                    }
                ]
            },
            {
                type: 'math',
                imgURL: '/images/hat/chart-2.png',
                questions: [{
                    'id': 'fiasdasdasve',
                    'number': '17',
                    'question': 'Percentage of urban women facing the problem of traveling long distances is what percentage less than the percentage of rural women who face the same problem?',
                    'choices': [
                        ' 9.09%',
                        '11.11%',
                        '14.28%',
                        '28.57%'
                    ],
                    answer: ''
                }, {
                    'id': 'siasedsvdsadasx',
                    'number': '18',
                    'question': 'If there are a total of 62,500 rural women, then the difference between rural women facing the problem of ‘sexual assault’ and ‘withholding food’ is:',
                    'choices': [
                        '1250',
                        '1375',
                        '1490',
                        '1610'
                    ],
                    answer: ''
                }]
            }]

    },
    {
        id: '',
        type: '',
        name: 'Logical Reasoning',
        cutoff: '1',

        pages: [

            {

                type: 'plain',
                questions: [{
                    'id': 'seasdasven',
                    'number': '19',

                    'question': 'Look at this series: 7, 10, 8, 11, 9, 12, ... What number should come next?',
                    'choices': [
                        '7',
                        '10',
                        '12',
                        '13'
                    ],
                    answer: ''
                }, {
                    'id': 'eigasdasdasht',
                    'number': '20',
                    'question': 'Play is to actor as concert is to _____________',
                    'choices': [
                        'symphony',
                        'musician',
                        'piano',
                        'percussion'
                    ],
                    answer: ''
                }]
            }
        ]

    }
]

export default sections

