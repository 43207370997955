import Mcq from "./Mcq"

export default function MathQuestion({ imgURL, mcqs }) {

    return (

        <div className="grid grid-cols-2 gap-4">
            <div className="max-h-[calc(100vh-14rem)] relative">
                <img className="block m-auto absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]" src={imgURL} alt="" />
            </div>
            <div className="max-h-[calc(100vh-10rem)] overflow-y-scroll">
                {
                    mcqs.map((mcq) => {

                        return (

                            <Mcq mcq={mcq} />

                        )
                    })
                }
            </div>
        </div>

    )

}