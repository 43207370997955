import { useFormik } from 'formik';

import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import UserUtils from '../../Utils/UserUtils';
import Loader from '../../Global_Components/Loader';
import { Helmet } from 'react-helmet';

export default function Details() {

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const DetailsSchema = Yup.object().shape({
        name: Yup.string()
            .matches(/^[a-zA-Z\s]+$/, 'Name can only contain letters and spaces')
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        phone: Yup.string()
            .max(10, 'Please Enter A 10 Digit Phone Number')
            .min(10, 'Please Enter A 10 Digit Phone Number')
            .required('Required')

    });


    const formik = useFormik({
        initialValues: {
            name: '',
            phone: '',
            email: '',
        },
        validationSchema: DetailsSchema,
        onSubmit: async values => {
            setLoading(true)
            const result = await UserUtils.newUser(values)
            console.log('result :', result);
            if (result) {
                navigate("/hat/rules");
            }
            else
                setLoading(false)
        },
    });

    useEffect(() => {

    }, [])

    return (
        <>
            <Helmet>
                <title>{'Hashpro Assessment Test'}</title>
                <meta name="description" content="Hashpro Assessment Test" />
                <meta property="og:title" content="Your Open Graph Title" />
                <meta property="og:description" content="Your Open Graph Description" />
                <meta property="og:image" content="URL to Your Image" />
                <meta property="og:url" content="URL to Your Web Page" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Your Site Name" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:title" content="Your Twitter Title" />
                <meta name="twitter:description" content="Your Twitter Description" />
                <meta name="twitter:image" content="URL to Your Image" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className='bg-cross-grid'>
                <div className=''>
                    <img className='w-40 py-12' src="/logos/hashpro-white.png" alt="" />
                </div>
                <div className='w-[60%] mx-auto pb-24'>

                    <div className='bg-hashpro text-black px-12 py-6  rounded-3xl border-2 border-black shadow-[5px_5px_white]'>

                        <h1 className="font-semibold text-3xl text-center leading-snug">HashPro Assessment Test (HAT)</h1>
                        <p className='text-center text-xl font-medium text-gray-800 '>Register yourself for the test</p>

                        <form onSubmit={formik.handleSubmit}>

                            <div className="flex flex-col grid-cols-[15%_85%] gap-6 w-full mx-auto mt-8">

                                <div>
                                    <label className='block font-semibold text-sm my-auto' htmlFor="name">First Name </label>

                                    <input
                                        id="name"
                                        name="name"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.name}
                                        className='w-full h-fit block my-auto text-black font-semibold border-black border-2'
                                    />
                                    {formik.errors.name && formik.touched.name && <span className='form-error'>{formik.errors.name}</span>}
                                </div>
                                <div>
                                    <label className='block font-semibold text-sm my-auto' htmlFor="phone">Phone </label>
                                    <input
                                        id="phone"
                                        name="phone"
                                        type="text"
                                        onChange={formik.handleChange}
                                        value={formik.values.phone}
                                        className='w-full h-fit block my-auto text-black font-semibold border-black border-2'

                                    />
                                    {formik.errors.phone && formik.touched.phone && <span className='form-error'>{formik.errors.phone}</span>}

                                </div>
                                <div>

                                    <label className='block font-semibold text-sm my-auto' htmlFor="email">Email Address </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email}
                                        className='w-full h-fit block my-auto text-black font-semibold border-black border-2'

                                    />
                                    {formik.errors.email && formik.touched.email && <span className='form-error'>{formik.errors.email}</span>}

                                </div>

                            </div>
                            {console.log(loading)}
                            <button className=' block mx-auto black text-lg mt-8' type="submit"> {loading ? <div className='w-6'> <Loader /></div> : 'Submit'} </button>

                        </form>
                    </div>

                </div>
            </section>
        </>
    )
}