import React, { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import "./corousel.css";

const CarouselComponent = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    const container = document.querySelector(".our-work");
    const listItems = gsap.utils.toArray(".carousel__nav__item");
    const slides = gsap.utils.toArray(".carousel__item");
    const tl = gsap.timeline();

    const myST = ScrollTrigger.create({
      animation: tl,
      id: "st",
      trigger: container,
      start: "top top",
      end: "+=" + container.clientHeight * (slides.length - 1),
      pin: container,
      scrub: true,
      snap: {
        snapTo: 1 / (slides.length - 1),
      },
      markers: false,
    });

    gsap.set(slides, {
      xPercent: () => {
        return window.innerWidth < 768 ? 125 : 0;
      },
      yPercent: () => {
        return window.innerWidth > 768 ? 125 : 0;
      },
      scale: 0.5,
      opacity: 0,
    });

    listItems.forEach((item, i) => {
      item.addEventListener("click", (e) => {
        e.preventDefault();
        const percent =
          tl.labels[e.target.getAttribute("data-target")] / tl.totalDuration();
        const scrollPos = myST.start + (myST.end - myST.start) * percent;
        gsap.to(window, { duration: 2, scrollTo: scrollPos });
      });

      const previousItem = listItems[i - 1];
      if (previousItem) {
        tl.to(
          item,
          { background: "#ed3c3c", boxShadow: "0 0 16px #ed3c3c" },
          0.5 * (i - 1)
        )
          .to(
            slides[i],
            {
              opacity: 1,
              yPercent: 0,
              xPercent: 0,
              scale: 1,
            },
            "<"
          )
          .to(
            previousItem,
            { backgroundColor: "#424b58", boxShadow: "0 0 16px transparent" },
            "<"
          )
          .to(
            slides[i - 1],
            {
              opacity: 0,
              yPercent: () => {
                return window.innerWidth > 768 ? -125 : 0;
              },
              yPercent: () => {
                return window.innerWidth < 768 ? -125 : 0;
              },
              scale: 0.5,
            },
            "<"
          )
          .add("our-work-" + ++i);
      } else {
        gsap.set(item, {
          background: "#ed3c3c",
          boxShadow: "0 0 16px #ed3c3c",
        });
        gsap.to(
          slides[i],
          { yPercent: 0, xPercent: 0, opacity: 1, scale: 1, duration: 0 },
          0
        );
        tl.add("our-work-" + ++i, "+=0");
      }
    });

    return () => {
      // Cleanup if necessary
      myST.kill();
      tl.kill();
    };
  }, []); // Empty dependency array ensures this effect runs only once

  return (
    <section className="our-work">
      <div className="">
        <div id="parent-carosel">
          <div className="carosel-heading">
            <h1>Why HashPro..</h1>
          </div>
          <div id="coroselslider">
            <div className="carousel__slider">
              <div className="carousel__item" id="our-work-1">
                <div className="blurred-box__step">1</div>
                <h2 className="blurred-box__title">Question</h2>
                <div className="blurred-box__footer">
                  <span className="blurred-box__footer-line"></span>
                  <p className="blurred-box__text">answer.</p>
                </div>
              </div>
              <div className="carousel__item" id="our-work-2">
                <div className="blurred-box__step">2</div>
                <h2 className="blurred-box__title">Question</h2>
                <div className="blurred-box__footer">
                  <span className="blurred-box__footer-line"></span>
                  <p className="blurred-box__text">answer.</p>
                </div>
              </div>
              <div className="carousel__item" id="our-work-3">
                <div className="blurred-box__step">3</div>
                <h2 className="blurred-box__title">Question</h2>
                <div className="blurred-box__footer">
                  <span className="blurred-box__footer-line"></span>
                  <p className="blurred-box__text">answer.</p>
                </div>
              </div>
              <div className="carousel__item" id="our-work-4">
                <div className="blurred-box__step">4</div>
                <h2 className="blurred-box__title">Question</h2>
                <div className="blurred-box__footer">
                  <span className="blurred-box__footer-line"></span>
                  <p className="blurred-box__text">answer.</p>
                </div>
              </div>
              <div className="carousel__item" id="our-work-5">
                <div className="blurred-box__step">5</div>
                <h2 className="blurred-box__title">Question</h2>
                <div className="blurred-box__footer">
                  <span className="blurred-box__footer-line"></span>
                  <p className="blurred-box__text">answer.</p>
                </div>
              </div>
              <div className="carousel__item" id="our-work-6">
                <div className="blurred-box__step">6</div>
                <h2 className="blurred-box__title">Question</h2>
                <div className="blurred-box__footer">
                  <span className="blurred-box__footer-line"></span>
                  <p className="blurred-box__text">answer.</p>
                </div>
              </div>
            </div>
            <ul className="carousel__nav">
              <li className="carousel__nav__item" data-target="our-work-1"></li>
              <li className="carousel__nav__item" data-target="our-work-2"></li>
              <li className="carousel__nav__item" data-target="our-work-3"></li>
              <li className="carousel__nav__item" data-target="our-work-4"></li>
              <li className="carousel__nav__item" data-target="our-work-5"></li>
              <li className="carousel__nav__item" data-target="our-work-6"></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CarouselComponent;
