import Mcq from "./Mcq"

export default function PlainQuestion({ mcqs }) {


    return (

        <div className="grid-cols-2 gap-4">

            <div className="max-h-[calc(100vh-10rem)] overflow-y-scroll">
                {
                    mcqs.map((mcq) => {

                        return (

                            <Mcq mcq={mcq} />

                        )
                    })
                }
            </div>
        </div>

    )

}
