import { Link } from "react-router-dom";

export default function HATRules() {
    const element = document.documentElement;

    function enterFullScreen() {
        if (element.requestFullscreen) {
            element.requestFullscreen();
        } else if (element.mozRequestFullScreen) { /* Firefox */
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) { /* IE/Edge */
            element.msRequestFullscreen();
        }
    }



    return (


        <section className="bg-cross-grid min-h-[100vh] pb-16">

            <div className='flex gap-3'>
                <img className='w-40 py-12' src="/logos/hashpro-white.png" alt="" />  <h2 className="block h-fit my-auto text-xl font-light"> | HashPro Assessment Test (HAT)</h2>
            </div>

            <h1 className="font-semibold text-3xl">Rules</h1>
            <p className="font-medium text-xl mt-6">Please read the instructions carefully before starting the test: </p>
            <ol className="mt-4 flex flex-col gap-4">
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    The test consists of 3 sections and a total of 20 questions.
                </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    Three sections include: English (9 Questions), Mathematics (9 Questions), and Logical ability (2 Questions).                </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    It is a time-bound test. Specified 20 minutes is the total test time.                 </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    A candidate will be able to revisit a question once answered.
                </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    You are allowed to use a calculator and scratch paper during the test.                 </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    English section covers reading comprehension passages, each followed by questions and a few grammar questions.                  </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    Mathematics section covers arithmetic and data interpretation questions.                </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    Logical ability section covers sequence and analogy questions.
                </li>
                <li className="ml-[1.2rem]  font-extralight text-lg">
                    Kindly take the test at a convenient place where you have good wi-fi. It is suggested to take the test on a laptop/desktop as you can utilize the in-built calculator.                 </li>

                <p className="font-semibold text-2xl mt-12">Scoring system is as follows:</p>
                <p className="font-extralight text-lg">Participants will be subject to two distinct scoring criteria: Section-wise and Overall.
                </p>
                <p className="font-semibold text-lg">Section-wise cutoffs:</p>
                <p className="font-extralight text-lg"> <b className="font-medium"> English:</b> Minimum of 5 correct answers out of 9.
                </p>
                <p className="font-extralight text-lg"><b className="font-medium">Mathematics: </b> Minimum of 5 correct answers out of 9.
                </p>
                <p className="font-extralight text-lg"> <b className="font-medium"> Logical Ability:</b> Minimum of 1 correct answer out of 2.
                </p>
                <p className="font-extralight text-lg"><b className="font-medium">Overall cutoff: </b>Candidates must achieve a total of 13 correct answers out of the 20 questions across all sections to successfully clear the evaluation.
                </p>

                <p className="font-normal text-lg mt-12">
                    If you require further clarifications, you can contact the teammate who has shared you this test.
                </p>
                <p className="font-medium text-2xl mt-12">
                   All the very best !
                </p>
            </ol>
            <div className="mt-12">
                <Link onClick={() => enterFullScreen()} to='/hat/test/0/0' className="w-fit block mx-auto">
                    <button className="yellow mx-auto text-lg mt-8">Start Test Now</button>
                </Link>
                <p className="text-gray-200 text-xs text-center mt-3">* Your timer will start & you won't be able to quit</p>
            </div>
        </section>


    )
} 