import { useState } from "react";
import { icons } from "../../../Global_Components/Icons"
import { Button, Tooltipm, Dialog, DialogActions, DialogTitle, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import { deletePlacement } from "../scripts";

export default function JobCard({ data, type }) {

    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    async function removePlacement() {
        const res = await deletePlacement(data.id)
        if (res) {
            handleDeleteClose()
            alert('Placement Deleted')

        } else {
            alert('Error While Deleting, Please Refresh')
        }
    }

    const handleDeleteOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteDialogOpen(false);
    };

    return (
        <>
            <div className="overflow-hidden m-auto p-6 shadow-black w-fit h-full max-h-96 max-w-[400px] bg-gradient-to-r from-[#e9c01d] to-[#c19f16]  border-light border-solid border-2 ">
                <p className="w-fit rounded-sm text-xl uppercase font-bold">{data.jobTitle}</p>
                <p className="text-sm text-light-black font-semibold">{data.company}</p>
                <div className="mt-5  text-clip overflow-hidden">
                    <span className=" text-sm line-clamp-4"><span className="text-sm font-bold">Role :</span>{data.jd}</span>
                </div>
                <div className="mt-5">
                    <div className="flex">
                        <img className="w-4 h-4" src={icons.CursorBlack} />&nbsp;<a href={data.website} target="_blank" className="truncate text-sm font-medium  text-black underline">{data.website}</a>
                    </div>
                    <div className="flex">
                        <img className="w-4 h-4 my-auto invert" src={icons.EmailWhite} />&nbsp;<a href={'mailto:' + data.mail} className="truncate text-sm font-medium text-black underline">{data.mail}</a>
                    </div>
                    <br />

                    <div className="flex">
                        <img className="w-4 h-4 my-auto invert" src={icons.LocationWhite} /><span className="ml-1 text-sm my-auto font-semibold">{data.jobLocation}</span>
                    </div>
                </div>
                <a href={data.buttonLink} target="_blank"><button className="uppercase mt-6 bg-gradient-to-r text-white font-bold from-[#8e670b] to-[#835f09]  transition duration-75 rounded-3xl border-0 p-2 px-8 border-solid border-black hover:bg-hashpro">{data.buttonName}</button></a>
                {
                    type == 'edit' ?
                        <div className="flex justify-between">
                            <Tooltip title="Edit" placement="bottom">
                                <Link to={`/placements/admin/edit`} state={{ 'placementData': data }} ><img className="w-10 h-10" src={icons.EditBlack} /></Link>
                            </Tooltip>

                            <Tooltip title="Delete" placement="bottom">
                                <img onClick={handleDeleteOpen} className="w-8 h-8 cursor-pointer" src={icons.DeleteBlack} />
                            </Tooltip>
                        </div >
                        : <></>
                }
            </div>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure you want to delete ? `}
                    <br />
                    {`${data.jobTitle}`}
                    <br />
                    {`${data.company}`}

                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>No</Button>
                    <Button onClick={removePlacement} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    )
}