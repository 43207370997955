import { Link, Route, Routes, useNavigate } from "react-router-dom";
import MasterClass from "./MasterClass";
import SaturdayCalendar from "../../Global_Components/SaturdayCalendar";
import { useMasterClass } from "../../Providers/MasterclassProvider";
import CalendarPage from "./CalendarPage";
import { IoCloseCircleSharp } from "react-icons/io5";
import GlobalUtils from "../../Utils/GlobalUtils";
import { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../Global_Components/Loader";


export default function MasterClassContainer() {

    const navigate = useNavigate()

    const MasterClassOptions = useMasterClass()
    const [seatsFilledPercentage, setSeatsFilledPercentage] = useState()
    const [loadingSeats, setLoadingSeats] = useState(false)

    function bookMorningSlot() {
        MasterClassOptions.setMasterClassSlot('morning')
        MasterClassOptions.setConfirmDateOpen(false)
        MasterClassOptions.setMasterClassOpen(false)
        navigate('/dmx-masterclass')
    }

    function bookEveningSlot() {
        MasterClassOptions.setMasterClassSlot('evening')
        MasterClassOptions.setConfirmDateOpen(false)
        MasterClassOptions.setMasterClassOpen(false)
        navigate('/dmx-masterclass')
    }

    useEffect(() => {

        if (MasterClassOptions.masterClassDate != '') {
            getSeats()
        }

    }, [MasterClassOptions.masterClassDate])


    function getSeats() {
        const savedSeats = MasterClassOptions.masterClassSeats
        const masterClassDate = GlobalUtils.convertToDDMMYYYY(MasterClassOptions.masterClassDate)

        for (const seat of savedSeats) {

            if (seat.date == masterClassDate) {
                setSeatsFilledPercentage(seat)
            }
        }
    }

    return (
        <>
            <div className={`${MasterClassOptions.masterClassIsOpen ? 'top-0' : 'top-[-120%]'} transition-all fixed left-0 right-0 h-[100vh] z-[9998] overflow-y-scroll overflow-x-hidden  bg-[#101010] mt-2`}>

                <div className="relative h-full w-full">
                    <div className={` mt-24 md:mt-0 mx-0 md:mx-0 sm:absolute left-4 right-4 top-1/2  md:-translate-y-[40%]`}>

                        {/* <div onClick={() => MasterClassOptions.setMasterClassOpen(false)} className="absolute top-0 -translate-y-8 right-4">
                            <IoCloseCircleSharp size={30} />
                        </div> */}

                        {
                            MasterClassOptions.masterClassPage == 'calendar' ?
                                <div className="mx-auto w-fit">
                                    <CalendarPage />
                                </div>
                                :
                                <div className="w-full md:w-1/2 mx-auto">
                                    <MasterClass />
                                </div>
                        }
                    </div>
                </div>
            </div>

            <div className={`${MasterClassOptions.confirmDateOpen ? 'top-1/2 -translate-y-1/2' : 'top-[120%]'} transition-all fixed left-1/2 -translate-x-1/2 w-full h-fit  z-[9998]  mt-2`}>

                <div className="relative w-[90%] md:w-[50%] mx-auto h-full">
                    <div className="bg-[white] w-full mx-auto text-[black] p-8 sm:p-12 border-2 border-black border-solid shadow-[2px_2px_#f8d00d] rounded-2xl">
                        <div onClick={() => MasterClassOptions.setConfirmDateOpen(false)} className="absolute top-4 right-4">
                            <IoCloseCircleSharp size={30} />
                        </div>
                        <h4 className="text-2xl">Slots Available For  {MasterClassOptions.masterClassDate != '' ? GlobalUtils.convertToDDMMYYYY(MasterClassOptions.masterClassDate) : 'No Date Format'}</h4>

                        {!loadingSeats ?
                            <>
                                <div className="mt-5">
                                    <p className="font-medium">Morning Slots : {Math.trunc(seatsFilledPercentage?.morningFilled)}% Filled</p>
                                    <p className="font-medium">Evening Slots : {Math.trunc(seatsFilledPercentage?.eveningFilled)}% Filled</p>
                                </div>

                                <div className="flex flex-col md:flex-row gap-2 mt-5">
                                    <>
                                        {seatsFilledPercentage?.morningFilled < 100 ?

                                            <button onClick={() => bookMorningSlot()} className="black">Book For Morning</button>
                                            :
                                            <button className="yellow">Slots Filled For Morning</button>
                                        }

                                        {seatsFilledPercentage?.eveningFilled < 100 &&

                                            <button onClick={() => bookEveningSlot()} className="black">Book For Evening</button>
                                            // :
                                            // <button className="yellow">Slots Filled For Evening</button>

                                        }
                                    </>

                                </div>
                            </>
                            :
                            <Loader />
                        }
                    </div>
                </div>

            </div >
        </>
    )
}