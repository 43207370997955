import { Link } from "react-router-dom"

export default function Navbar() {
    return (

        <>
            <div className="w-full bg-[#131313] flex justify-evenly py-2">
                <Link to='/placements/admin/add' className="text-lg hover:underline">Add Placement</Link>
                <Link to='/placements/admin/manage' className="text-lg hover:underline">Edit/Delete Placement</Link>
                <Link to='/placements/admin/manage-students' className="text-lg hover:underline">Manage Students</Link>

            </div>
        </>

    )

}