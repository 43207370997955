import React, { useState } from 'react';


const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const questions = [
    {
      question: "Is this course available online?",
      answer: "No, our course is entirely conducted offline at our Academy located in Himayat Nagar."
    },
    {
      question: "What is the duration of each class?",
      answer: "Each class has a duration of 150 - 180 minutes. Classes are held from Monday to Saturday."
    },
    {
      question: "Do you offer job assistance?",
      answer: "Our program primarily focuses on skills enhancement and entrepreneurship skill development, preparing individuals for personal and professional growth."
    },
    {
      question: "Is there an EMI Option available for payment?",
      answer: "Yes, we offer the flexibility to choose the EMI option for payment."
    },
    {
      question: "Is any commitment required for the course?",
      answer: "Yes, participants are required to commit to the program for the entire 35+ days duration without missing any classes."
    },
    {
      question: "Who can benefit from this course?",
      answer: "This program is designed to benefit a diverse audience, including students, business enthusiasts, startup founders, graduates, and individuals from various educational backgrounds."
    }
  ];

  return (
    <div className="questionss">
      {questions.map((item, index) => (
        <div
          key={index}
          className={`question-containerr ${activeIndex === index ? 'show' : 'collapsed'}`}
          onClick={() => handleClick(index)}
        >
          <h3 className="question">
            <p>{item.question}</p>
            <img
              src="/global/icons/faq-arrow.webp"
              style={{ transform: activeIndex === index ? 'rotate(180deg)' : 'rotate(0deg)' }}
              alt="Arrow"
            />
          </h3>
          {activeIndex === index && <p className="answer">{item.answer}</p>}
        </div>
      ))}
    </div>
  );
};

export default FAQSection;
