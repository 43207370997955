import React, { forwardRef } from 'react';

const PrintReceipt = forwardRef(({ data }, ref) => {
    return (
        <div ref={ref} className="w-full  h-full p-12">
            <div className="mt-6 bg-white text-black">
                <img className='w-44 mb-12' src="/global/logos/hashpro-black.png" alt="" />
                <div className="flex flex-col gap-4">

                    <div className="flex">
                        <h1>Order ID : &nbsp;</h1>
                        <p>{data.order_id}</p>
                    </div>
                    <div className="flex">
                        <h1>Name : &nbsp;</h1>
                        <p>{data.billing_name}</p>
                    </div>
                    <div className="flex">
                        <h1>Phone : &nbsp;</h1>
                        <p>{data.billing_tel}</p>
                    </div>
                    <div className="flex">
                        <h1>Email : &nbsp;</h1>
                        <p>{data.billing_email}</p>
                    </div>
                    <div className="flex">
                        <h1>Transaction Status : &nbsp;</h1>
                        <p>{data.order_status}</p>
                    </div>
                    <div className="flex">
                        <h5> Amount : &nbsp;</ h5>
                        <p>{data.amount}</p>
                    </div>
                    <div className="flex">
                        <h1>Transaction Date : &nbsp;</h1>
                        <p>{data.trans_date}</p>
                    </div>


                </div>

                <div className="mt-20 w-fit ml-auto">
                    <p className='font-light '>Signature</p>
                </div>
            </div>
        </div>
    );
});

export default PrintReceipt;
