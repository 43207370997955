import { getAuth } from "firebase/auth"
import { Button } from "@mui/material";
import Footer from "../../../Global_Components/Footer";
import Header from "../../../Global_Components/Header";
import { Link } from "react-router-dom";

export default function InvalidUser() {

    return (
        <>
            <Header />
            <div className="bg-[#131313] h-[100vh] px-10 pt-24 ">
                <h1 className=" text-3xl font-normal pt-12 text-hashpro">Sorry, you are not a part of Hashpro Academy :(</h1>
                <h1 className="mt-4 text-sm font-normal text-white">Please Login with valid mail</h1>
                <h1 className="text-sm font-normal text-white">Currently Logged in with <span className="text-hashpro font-poppins">{getAuth().currentUser.email}</span></h1>


                <h2 className="text-center text-2xl "></h2>
                <div className="flex mt-12">
                    <div className="">
                        <a href='/'><Button variant='contained' className="yellow" >Home Page</Button></a>
                    </div>
                    <div className="ml-4">
                        <Button variant='contained' className="yellow" onClick={() => { getAuth().signOut(); window.location.href='/sign-in' }}>Sign Out</Button>
                    </div>

                </div>
            </div >
            <Footer />
        </>

    )

}