import { useEffect, useState } from "react"
import { useAuth } from "../../Providers/AuthProvider";
import axios from "axios";
import Loader from "../../Global_Components/Loader";

export default function UserPaymentHistory() {

    const [payments, setPayments] = useState({})
    const { currentUser } = useAuth()

    useEffect(() => {
        axios.post(process.env.REACT_APP_SERVER_URL + '/user/get-payment-history', {
            email: currentUser.email
        }).then(function (response) {
            setPayments(response.data)
            console.log(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, [])

    useEffect(() => {
        console.log(payments)
    }, [payments])

    return (
        <section className="mt-52 mb-24">
            <h1 className="text-3xl font-semibold">
                Payment History
            </h1>
            <div className="mt-12">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 ">

                    {


                        (Array.isArray(payments) && payments.length > 0)
                            ?
                            payments.map(payment => {
                                return (
                                    <div className="flex flex-col gap-4 bg-[#202020] w-full sm:w-fit  p-6 rounded-xl">
                                        <p className="text-sm"> <b>Order Id : </b>  {payment.id}</p>
                                        <p className="text-sm"> <b>Name : </b>  {payment.billing_name}</p>
                                        <p className="text-sm"> <b>Amount : </b> {payment.amount}</p>
                                        <p className="text-sm"> <b>Email : </b> {payment.billing_email}</p>
                                        <div className="flex justify-between">
                                            {
                                                payment.status == 'Success' ?
                                                    <p className="w-fit text-xs mt-4 bg-green-700   py-1 px-3 rounded-2xl">Success</p>
                                                    :
                                                    payment.status == 'Failed' ?
                                                        <p className="w-fit text-xs mt-4 bg-red-600 py-1 px-3 rounded-2xl">Failed</p>
                                                        :
                                                        <p className="w-fit text-xs mt-4 bg-red-600 py-1 px-3 rounded-2xl">Cancelled</p>

                                            }
                                            <a className="w-fit text-xs mt-4 bg-[#131313] hover:scale-105 transition py-1 px-3 rounded-2xl border border-solid border-gray-400" href={`/payments/transaction/${payment.order_id}/${payment.tracking_id}`}>View Payment</a>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            (Array.isArray(payments) && payments.length == 0)
                                ?
                                <>
                                    <h1>No Payments Yet</h1>
                                </>
                                :
                                <>
                                    <Loader />
                                </>

                    }
                </div>

            </div>
        </section>
    )
}
