import { getAuth } from "firebase/auth"
import { useEffect, useState } from "react"
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../..";
import { redirectDocument } from "react-router-dom";
import { Button } from "@mui/material";
import InvalidUser from "../Components/InvalidUser";
import Loader from "../../../Global_Components/Loader";

export default function StudentRoute({ children }) {

    const fieldName = 'email'
    const studentEmail = getAuth().currentUser.email
    console.log(studentEmail)

    const studentCollection = collection(db, 'Students');

    const q = query(studentCollection, where(fieldName, '==', studentEmail));

    const [isHashpro, setHashpro] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const studentQuery = async () => {
        try {
            const querySnapshot = await getDocs(q);
            console.log(querySnapshot.size != 0)

            if (querySnapshot.size != 0) {
                setHashpro(true)
                setLoaded(true)
            } else {
                checkifAdmin()
            }
        } catch (error) {
            console.log('Firebase Error', error)
        }
    }

    const checkifAdmin = async () => {
        const adminCollection = collection(db, 'Admins');

        const a = query(adminCollection, where(fieldName, '==', studentEmail));

        try {
            const querySnapshot = await getDocs(a);
            console.log(querySnapshot.size != 0)
            setLoaded(true)

            if (querySnapshot.size != 0) {
                setHashpro(true)
            }

        } catch (error) {
            console.log('Firebase Error', error)
        }
    }

    useEffect(() => {
        studentQuery()
    })

    if (isHashpro && loaded) {
        return (
            <>
                {children}
            </>
        )

    } else if (!isHashpro && loaded) {

        return (
            <>
                <InvalidUser />
            </>
        )

    } else {
        return (
            <><Loader /></>
        )
    }

};
