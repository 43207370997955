import React, { useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function AddJob() {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const onSaveClick = () => {
    // Get the raw editor content
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);

    // Convert raw content state to HTML
    const htmlContent = stateToHTML(contentState);
    console.log(htmlContent);
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
      <button onClick={onSaveClick}>Save Content</button>
    </div>
  );
}