import JobCard from "./JobCard";
import { fetchPlacements } from "../scripts";
import { useEffect, useState } from "react"
import Loader from "../../../Global_Components/Loader";
import { Skeleton } from "@mui/material";

export default function RenderPlacements({ type }) {

    const [placementData, setPlacementData] = useState([])

    async function getPlacements() {
        const data = await fetchPlacements()
        setPlacementData(data)
    }

    useEffect(() => {
        getPlacements()
    }, [])

    return (
        <>
            <div className='flex flex-wrap gap-y-6 gap-x-6 grid-cols-1 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 py-6 px-6'>

                {placementData != [] ? (placementData.map((placement) => {
                    console.log(placement)
                    return (
                        <JobCard key={placement.id} data={placement} type={type} />
                    )
                })
                ) :

                    // Show Skeleton 
                    <>
                        <div className="mx-auto">
                            <Skeleton animation="wave" variant="rectangular" width={400} height={300} />
                        </div>
                        <div className="mx-auto">
                            <Skeleton animation="wave" variant="rectangular" width={400} height={300} />
                        </div>
                        <div className="mx-auto">
                            <Skeleton animation="wave" variant="rectangular" width={400} height={300} />
                        </div>
                        <div className="mx-auto">
                            <Skeleton animation="wave" variant="rectangular" width={400} height={300} />
                        </div>
                    </>
                }
            </div>
        </>
    )
}