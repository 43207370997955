export default function PrivacyPolicy() {

    return (

        <section>
            <div class="policy-page-container">

                <div class="policy-heading">
                    <h1 class="heading">
                        Privacy Policy
                    </h1>
                </div>
                <div class="policy-body">

                    <p>This Privacy Policy outlines how we collect, use, disclose, and protect your personal information. By
                        accessing or enrolling into the programs, you agree to the terms of this policy.</p>
                    <h2>Collection of Information:</h2>
                    <p>We collect personal information such as name, email address, phone number, payment details and few other
                        required fields, when you register for program or at the time of enquiry. We may also collect
                        information automatically through cookies and similar tracking technologies.</p>
                    <h2>Use of Information:</h2>
                    <ul>
                        <li>Personal information is used to provide and improve our services, communicate with you, and send
                            promotional materials.</li>
                        <li>We do not share your personal information with third parties for their marketing purposes.</li>
                        <li>Credit card information may be shared with our finance partners for payment processing purposes
                            only.</li>
                    </ul>
                    <h2>Protection of Information:</h2>
                    <ul>
                        <li>We implement security measures to protect your personal information from unauthorized access,
                            alteration, disclosure, or destruction.</li>
                        <li>However, no method of transmission over the internet or electronic storage is 100% secure, and we
                            cannot guarantee absolute security.</li>
                    </ul>
                    <h2>Retention of Information:</h2>
                    <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in
                        this policy unless a longer retention period is required or permitted by law.</p>
                    <h2> Your Rights:</h2>
                    <ul>
                        <li>You have the right to access, correct, or delete your personal information. You may also opt-out of
                            receiving promotional communications from us.</li>
                        <li>To exercise these rights or inquire about our privacy practices, please contact the academy. </li>
                    </ul>
                    <h2>Updates to Policy:</h2>
                    <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the
                        effective date will be updated accordingly.</p>
                </div>

            </div>
        </section>
    )
}
