import React, { useEffect, useLayoutEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../Global_Components/Loader";
import axios from "axios"
import { IoArrowBackSharp } from "react-icons/io5";
import { MasterClassProvider, useMasterClass } from "../../Providers/MasterclassProvider";
import GlobalUtils from "../../Utils/GlobalUtils";

export default function MasterClass() {

  const [formStatus, setFormStatus] = useState("default");
  const [seatsFilledPercentage, setSeatsFilledPercentage] = useState("default");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const MasterClassOptions = useMasterClass()
  console.log(MasterClassOptions)

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    location: '',
    slot: "",
    bookingDate: MasterClassOptions.masterClassDate != '' ? GlobalUtils.convertToDDMMYYYY(MasterClassOptions.masterClassDate) : ''
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    console.log(values)
    setFormStatus('loading')
    setSubmitting(true)
    values.slot = MasterClassOptions.masterClassSlot

    axios.post(`${process.env.REACT_APP_SERVER_URL}/masterClass/registerUser`, {
      values
    }).then((data) => {
      console.log(data)
      setSubmitting(false);
      setFormStatus('submitted')
    }).catch((e) => {
      console.log(e)
      setSubmitting(false);
      setFormStatus('failed')

    });
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (

    <section className="py-24 pt-36">
      {
        MasterClassOptions.masterClassDate != ''
          ?
          <div className=" gap-12 lg:w-[50%] mx-auto">
            <div
              style={{ position: "relative", width: "100%" }}
              className="h-fit my-auto "
              id=""
            >

              {/* <div onClick={() => { MasterClassOptions.setMasterClassPage('calendar') }} className="flex gap-x-2 text-[white]">
            <IoArrowBackSharp className="h-fit my-auto " />
          </div> */}

              <p className="mt-4  text-white w-fit mx-auto p-2 py-1 rounded-xl "> <b className="font-bold"> <span className="text-hashpro"> Booking For Date :</span> </b> {GlobalUtils.convertToDDMMYYYY(MasterClassOptions.masterClassDate)}</p>
              <p className="mt-0  text-white w-fit mx-auto p-2 py-1 rounded-xl "> <b className="font-bold"> <span className="text-hashpro"> Slot : </span></b>  {capitalizeFirstLetter(MasterClassOptions.masterClassSlot)} ({MasterClassOptions.masterClassSlot == 'morning' ? '10:00AM - 12:00PM' : '06:00PM - 08:00PM'})  </p>

              <div className="container mt-8">
                <div className="info">
                  <h3 className="text-3xl text-center">Hey there! Fill the details</h3>
                </div>
                <Formik

                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}

                >
                  {({ isSubmitting }) => (
                    <Form
                      id="contact-form"
                      className="form-dark h-fit my-auto  inputs-container flex flex-col gap-y-4"
                    >
                      <div>
                        <Field
                          id="name"
                          className="border-black"
                          placeholder="Enter Your Name"
                          type="text"
                          name="name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div>
                        <Field
                          id="email"
                          placeholder="Enter Your Email"
                          type="email"
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div>

                        <Field
                          id="phone"
                          placeholder="Enter Your Phone No."
                          type="tel"
                          name="phone"
                        />

                        <ErrorMessage
                          name="phone"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div>
                        <Field
                          id="name"
                          className="border-black"
                          placeholder="Enter Your Location"
                          type="text"
                          name="location"
                        />
                        <ErrorMessage
                          name="location"
                          component="div"
                          style={{ color: "red" }}
                        />
                      </div>
                      {/* <div>
                     <div id="my-radio-group" className="text-[white]">
                      Choose Slot :
                    </div>
                    <div>
                      <div className="flex ">
                        <div className={`${seatsFilledPercentage.morningFilled == 100 && 'hidden'} flex gap-x-1`}>
                          <Field
                            className="w-fit h-fit my-auto"
                            type="radio"
                            name="slot"
                            value="morning"
                            id='morning-slot'
                            disabled={seatsFilledPercentage.morningFilled == 100}
  
                          />
                          <label htmlFor="morning-slot" className="text-[white] h-fit my-auto">
                            Morning
                          </label>
                        </div>
  
                        <div className="">
                          {seatsFilledPercentage.morningFilled == 100 &&
                            <>
                              {" "} <span className="bg-hashpro text-[black] font-semibold px-2 rounded-xl my-auto h-fit">Morning Seats Filled</span>
                            </>
                          }
                        </div>
  
                        <div className={`${seatsFilledPercentage.eveningFilled == 100 && 'hidden'} flex gap-x-1`}>
  
                          <Field
                            className={`w-fit ml-4`}
                            type="radio"
                            name="slot"
                            value="evening"
                            id='evening-slot'
                            disabled={seatsFilledPercentage.eveningFilled == 100}
  
                          />
  
                          <label htmlFor="evening-slot" className="block text-[white]">
                            Evening
  
                          </label>
                        </div>
                        <div className="ml-3">
                          {seatsFilledPercentage.eveningFilled == 100 &&
                            <>
                              {" "} <span className="bg-hashpro text-[black] font-semibold px-2 rounded-xl my-auto h-fit">Evening Seats Filled</span>
                            </>
                          }
                        </div>
                      </div>
                    
                    </div> 
                  </div>*/}

                      <div className="center">

                        {
                          seatsFilledPercentage.morningFilled == 100 && seatsFilledPercentage.eveningFilled == 100
                            ?
                            <button type="button"
                              className="mt-4 yellow submit-btn w-full"
                            >
                              Sorry All Seats Are Filled, Book Now For Next Week
                            </button>
                            :
                            <button
                              type="submit"
                              className=" yellow submit-btn w-full"
                              disabled={
                                isSubmitting ||
                                formStatus == "loading" ||
                                formStatus == "submitted"
                              }
                            >
                              {formStatus === "loading" ? (
                                <Loader />
                              ) : formStatus === "failed" ? (
                                "Failed, Submit Again"
                              ) : formStatus === "submitted" ? (
                                <span>
                                  {" "}
                                  <span className="rounded-lg">
                                    {" "}
                                    Form Has Been Submitted
                                  </span>
                                  <br />{" "}
                                  <span className="text-xs mt-1 inline-block">
                                    {" "}
                                    Congratulations, You Will Receive A Mail regrading your registration
                                  </span>
                                </span>
                              ) : (
                                "Submit"
                              )}
                            </button>
                        }



                      </div>

                      <ErrorMessage
                        name="general"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>

          </div >
          :
          <p className="text-2xl py-40 font-semibold leading-relaxed">Please select a date to book <span className="text-hashpro"> DMX masterclass</span> from the navigation bar at the top</p>
      }
    </section >
  );
};
