import { getAuth } from "firebase/auth"
import { useEffect, useState } from "react"
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from "../../..";
import { Button } from "@mui/material";
import InvalidUser from "../Components/InvalidUser";
import Loader from "../../../Global_Components/Loader";

export default function AdminRoute({ children }) {

    const fieldName = 'email'
    const adminEmail = getAuth().currentUser.email

    const studentCollection = collection(db, 'Admins');

    const q = query(studentCollection, where(fieldName, '==', adminEmail));

    const [isAdmin, setAdmin] = useState(false)
    const [loaded, setLoaded] = useState(false)

    const adminQuery = async () => {
        try {
            const querySnapshot = await getDocs(q);
            console.log(querySnapshot.size != 0)

            setLoaded(true)
            if (querySnapshot.size != 0) {
                setAdmin(true)
            }
        } catch (error) {
            console.log('Firebase Error', error)
        }
    }

    useEffect(() => {
        console.log(isAdmin)
    },[isAdmin])

    useEffect(() => {
        adminQuery()
    }, [])

    if (isAdmin && loaded) {
        return (
            <>
                {children}
            </>
        )
    } else if (!isAdmin && loaded) {

        return (
            <InvalidUser />
        )
    } else {
        return (
            <><Loader /></>
        )
    }

};
