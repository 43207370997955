import { useState, useEffect } from "react";
import { useTestContext } from "../../../Providers/HATProvider";

export default function NoQuestionsAttended() {
    const { globalQuestions, total, attempted } = useTestContext();

    function calculate() {

    }


    useEffect(() => {
        calculate()
    }, [globalQuestions])

    return (
        <>
            <h1 className="h-fit my-auto"> <span className="font-semibold text-xl"> {attempted}/{total}</span> Attempted</h1>
        </>
    )
}