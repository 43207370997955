import { useForkRef } from "@mui/material";
import { useAuth } from "../../Providers/AuthProvider";
import PaymentUtils from "./PaymentUtils";
import { useFormik } from "formik";
import * as Yup from "yup";

export default function Payment() {
  const { currentUser } = useAuth();

  const formik = useFormik({
    initialValues: {
      tid: "",
      merchant_id: 3274726,
      order_id: PaymentUtils.generate_order_id(),
      currency: "INR",
      redirect_url: `${process.env.REACT_APP_SERVER_URL}/payments/ccavResponseHandler`,
      cancel_url: `${process.env.REACT_APP_SERVER_URL}/payments/ccavResponseHandler`,
      language: "EN",
      billing_name: "",
      billing_tel: "",
      billing_email: currentUser.email,
      amount: "",
    },
    validationSchema: Yup.object({
      billing_name: Yup.string().required("Required"),
      billing_tel: Yup.string()
        .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
        .required("Required"),
      billing_email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      amount: Yup.number()
        .typeError("Amount must be a number")
        .min(11, "Amount must be greater than 11")
        .required("Required"),
    }),
    onSubmit: (values) => {
      document.customerData.submit();
    },
  });

  return (
    <div className="pt-20" i>
      <link rel="stylesheet" href="/global/css/global.css" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n .fee-form-container {\n            padding-top: 70px;\n            padding-bottom: 70px;\n\n        }\n\n        table {\n            margin: 10px;\n            border-spacing: 0 1em;\n            border-collapse: separate;\n\n        }\n    ",
        }}
      />

      <div className="fee-form-container">
        <form
          method="post"
          name="customerData"
          action={`${process.env.REACT_APP_SERVER_URL}/payments/ccavRequestHandler`}
          onSubmit={formik.handleSubmit}
        >
          <h1 className="text-3xl font-medium">Pay Fee</h1>
          <table width="40%" height={100}>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    hidden
                    name="tid"
                    id="tid"
                    readOnly
                    value={formik.values.tid}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    hidden
                    name="merchant_id"
                    value={formik.values.merchant_id}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    hidden
                    name="order_id"
                    id="order_id"
                    value={formik.values.order_id}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    hidden
                    name="currency"
                    value={formik.values.currency}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    hidden
                    name="redirect_url"
                    value={formik.values.redirect_url}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    hidden
                    name="cancel_url"
                    value={formik.values.cancel_url}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    hidden
                    name="language"
                    value={formik.values.language}
                  />
                </td>
              </tr>
              <tr>
                <td className="details-label">Name :</td>
                <td>
                  <input
                    type="text"
                    name="billing_name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.billing_name}
                    required
                  />
                  {formik.touched.billing_name && formik.errors.billing_name ? (
                    <div>{formik.errors.billing_name}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td className="details-label">Phone No :</td>
                <td>
                  <input
                    type="number"
                    name="billing_tel"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.billing_tel}
                    required
                  />
                  {formik.touched.billing_tel && formik.errors.billing_tel ? (
                    <div>{formik.errors.billing_tel}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td className="details-label">Email :</td>
                <td>
                  <input
                    type="email"
                    name="billing_email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.billing_email}
                    required
                  />
                  {formik.touched.billing_email &&
                  formik.errors.billing_email ? (
                    <div>{formik.errors.billing_email}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td className="details-label">Amount :</td>
                <td>
                  <input
                    type="text"
                    name="amount"
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div>{formik.errors.amount}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <button type="submit" className="black">
                    Proceed To Payment
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}
