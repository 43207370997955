import { Link } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import Payment from "../Payments/Payment";

export default function UserDashboard() {
    const { currentUser } = useAuth()
    return (
       <>
        <section className="pt-52">
            
            <h2 className="text-4xl">Welcome To Hashpro Academy</h2>
            


        </section>
        <section id="userdash-default-payform">
        <Payment />
        </section>
    
            
        
       </>
    )

}