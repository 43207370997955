import { useState } from "react";
import "./jd.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../Global_Components/Loader";
import { Helmet } from "react-helmet";
import { MdOutlineFileUpload } from "react-icons/md";

export default function JD() {
  const [formStatus, setFormStatus] = useState("default");

  const [fileName, setFileName] = useState("");

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    coverLetter: "",
    resume: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),

    message: Yup.string(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    var form_data = new FormData();
    form_data.append("resume", values.resume);
    values.resume = "Candiate Resume";
    form_data.append("formData", JSON.stringify(values));
    setFormStatus("loading");
    console.log(form_data);

    const jsonData = form_data;

    fetch(`${process.env.REACT_APP_SERVER_URL}/forms/jd`, {
      method: "POST",


      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setFormStatus("submitted");
        return response.text();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setFormStatus("failed");
      })
      .finally(() => {
        setSubmitting(false);
      });

    console.log(jsonData);
  };

  console.log(process.env.REACT_APP_SERVER_URL);
  return (
    <>
      <Helmet>
        <title>{"Business Development Associate"}</title>
        <meta name="description" content="Business Development Associate" />
        <meta property="og:title" content="Your Open Graph Title" />
        <meta property="og:description" content="Your Open Graph Description" />
        <meta property="og:image" content="URL to Your Image" />
        <meta property="og:url" content="URL to Your Web Page" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Your Site Name" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:title" content="Your Twitter Title" />
        <meta name="twitter:description" content="Your Twitter Description" />
        <meta name="twitter:image" content="URL to Your Image" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <section className="jd-container pt-12">
        <div className="grid">
          <div className="space-container">
            <h2 className="heading">Business Development Associate</h2>
            <div className="jd">
              <div className="qualification">
                <h3 className="jd-heading">
                  What Are We Looking For In A Candidate
                </h3>
                <div className="points">
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>
                      1-2 years experience in business sales or 6 months in the
                      educational industry.
                    </p>
                  </div>
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>
                      Excellent communication skills in Telugu, Hindi, and
                      English.
                    </p>
                  </div>
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>Strong sales and negotiation skills.</p>
                  </div>
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>Teamwork and collaboration.</p>
                  </div>
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>Passion driven and growth mindset.</p>
                  </div>
                </div>
              </div>
              <div className="jr">
                <h3 className="jd-heading">Job Responsibilties</h3>
                <div className="points">
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>Passion driven and growth mindset.</p>
                  </div>
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>
                      End to end coordination with students for all concerns
                      raised.
                    </p>
                  </div>
                  <div className="point">
                    <img src="/global/icons/arrow.webp" alt="" />
                    <p>
                      Ability to learn and adapt to any new practices and
                      changes in the Business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              style={{ width: "100%", height: "fit-content", margin: "0 auto" }}
              className="lead-form w-full"
              id="lead-form"
            >
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form
                    id="contact-form"
                    className="dark mt-6 inputs-container flex flex-col gap-y-4"
                  >
                    <div>
                      <Field
                        id="name"
                        className="border-black"
                        placeholder="Enter Your Name"
                        type="text"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="email"
                        placeholder="Enter Your Email"
                        type="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="phone"
                        placeholder="Enter Your Phone No."
                        type="tel"
                        name="phone"
                        
                      />
                      <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div>
                      <div className=" border-solid border-black border-[1px] rounded-[2rem] resumeBox">
                        <button class="container-btn-file" type="button">
                          <MdOutlineFileUpload className="text-lg" />
                          Upload Resume
                          <input
                            style={{ border: "none" }}
                            id="resume"
                            name="resume"
                            type="file"
                            accept=".doc,.pdf,.docx"
                            onChange={(event) => {
                              const file = event.currentTarget.files[0];
                              setFieldValue(
                                "resume",
                                event.currentTarget.files[0],

                                setFileName(file.name)
                              );
                            }}
                          />
                        </button>
                        <span className="text-black text-xs pl-4 mt-2 choosedfile">
                          {fileName ? fileName : "No File Choosen"}
                        </span>
                      </div>

                      <span className="text-black text-xs pl-4">
                        *Accepted Formats : .pdf, .doc, .docx (Below 10mb)
                      </span>
                      <ErrorMessage
                        name="resume"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div>
                      <Field
                        as="textarea"
                        name="coverLetter"
                        rows={3}
                        id="coverLetter"
                        placeholder="Enter Your Cover Letter"
                        type="text"
                      />
                    </div>
                    <div className="center">
                      <button
                        type="submit"
                        className="black submit-btn w-full"
                        disabled={
                          isSubmitting ||
                          formStatus == "loading" ||
                          formStatus == "submitted"
                        }
                      >
                        {formStatus === "loading" ? (
                          <Loader />
                        ) : formStatus === "failed" ? (
                          "Failed, Submit Again"
                        ) : formStatus === "submitted" ? (
                          "Application Has Been Submitted"
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                    <p style={{ color: "black", fontSize: "0.8rem" }}>
                      Sign up to receive updates and notifications. We respect
                      your privacy and will not override your DND or NDNC
                      preferences.
                    </p>
                    <ErrorMessage
                      name="general"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
            <div className="bg-hashpro p-3 rounded-xl text-black text-center mt-6 shadow-[2px_2px_white] border-black border-2 border-solid">
              <p className="text-xl font-bold ">Send Your Resume</p>
              <div className="mt-4 w-fit mx-auto flex gap-x-2 my-auto">
                {" "}
                <img
                  className="filter-black w-6 h-6 my-auto"
                  src="/global/icons/mail.webp"
                  alt=""
                />{" "}
                :{" "}
                <a
                  className="text-black my-auto hover:underline hover:cursor"
                  href="mailto:hr@hashproacademy.com"
                >
                  hr@hashproacademy.com
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
