
import { logos } from "./Logos"
import { Link, useLocation,useNavigate } from "react-router-dom"
import { Skeleton } from "@mui/material"
import './header.css'
import { useEffect, useLayoutEffect } from "react"
import playPageSwitch from "./pageSwitch"
import { useGSAP } from "@gsap/react"
import gsap, { ScrollTrigger } from "gsap/all"
import { AuthProvider, useAuth } from "../Providers/AuthProvider"
import UserUtils from "../Utils/UserUtils"

import MasterClassContainer from "../pages/MasterClass/MasterClassContainer"
import { useMasterClass } from "../Providers/MasterclassProvider"
// import { BubblyLink } from "react-bubbly-transitions"



function SignUpSwitch() {
    const { currentUser } = useAuth()
    

    return (

        <>
            {currentUser == null ?
                <a href="/sign-in">Sign In</a>
                :
                <>
                    <a href="/user">My Profile</a>
                    <br />
                    <a onClick={() => UserUtils.signOut()}>Sign Out</a>
                </>
            }
        </>
    )
}

export default function Header() {
    const navigate=useNavigate()

    const location = useLocation()
    const MasterClassOptions = useMasterClass()

    useLayoutEffect(() => {

        if (!location.pathname.startsWith('/hat')) {

            let coursesOpen = false
            const exploreCourses = document.getElementById('explore-courses')
            const coursesView = document.getElementById('courses-view')

            exploreCourses.addEventListener('click', () => {

                if (!coursesOpen) {
                    coursesView.style.top = '70px'
                } else {
                    coursesView.style.top = '-100%'
                }

                coursesOpen = !coursesOpen

            })

            const navbarMobileBtn = document.getElementById('nav-check');
            const navbarMobileLinks = document.getElementsByClassName('close-after-click');

            for (let i = 0; i < navbarMobileLinks.length; i++) {
                navbarMobileLinks[i].addEventListener('click', () => {
                    console.log('navbar clicked')
                    navbarMobileBtn.checked = false;
                })
            }
        }

    }, [])

    // useGSAP(() => {
    //     // gsap.registerPlugin(ScrollTrigger)
    //     gsap.to('.nav', {
    //         scrollTrigger: {
    //             trigger: 'body',
    //             start: 'top top',
    //             end: '100px top',
    //             // markers: true,
    //             toggleActions: 'none play reverse none'
    //         },
    //         ease:'linear',
    //         backgroundColor: 'black',
    //         padding: '1.5rem 0',
    //         color: 'black'
    //     })

    //     gsap.to('.nav >.nav-btn', {
    //         scrollTrigger: {
    //             trigger: 'body',
    //             start: 'top top',

    //             end: '100px top',
    //             // markers: true,
    //             toggleActions: 'none play reverse none'
    //         },
    //         ease:'linear',

    //         top: '13px',
    //     })
    // }, [])

    useEffect(() => {
        console.log(location)
    }, [])




    return (

        <>

            <MasterClassContainer />



            {!location.pathname.startsWith('/hat') && <div className="navbar">
                <div className="nav-container">
                    <div id="courses-view" className="courses-desktop">
                        <div className="grid">
                            <div className="coursess">
                                <h3>Courses</h3>
                                {/* <div className="course">
                                <Link
                                    onClick={() => playPageSwitch('Art Of Influence')}

                                    to="/art-of-influence/">Art Of Influence</Link>
                            </div>
                            <div className="course">
                                <Link
                                    onClick={() => playPageSwitch('Digital Marketing Xperential Bootcamp')}

                                    to="/digital-marketing-xperential-bootcamp">
                                    Digital Marketing Xperential Bootcamp
                                </Link>
                            </div>
                            <div className="course">
                                <Link
                                    onClick={() => playPageSwitch('Digital Marketing')}

                                    to="">Digital Marketing</Link>
                            </div> */}
                                <div className="course">
                                    {/* <img src="/global/icons/arrow.webp" alt=""> */}
                                    <a href="/art-of-influence/" onClick={() => playPageSwitch('Art Of Influence')}>
                                        Art Of Influence
                                    </a>
                                </div>
                                <div className="course">
                                    {/* <img src="/global/icons/arrow.webp" alt=""> */}
                                    <a href="/digital-marketing-xperential-bootcamp" onClick={() => playPageSwitch('Digital Marketing Xperential Bootcamp')}>
                                        Digital Marketing Xperential Bootcamp
                                    </a>
                                </div>
                                {/* <div className="course">
                                    <img src="/global/icons/arrow.webp" alt=""> 
                                    <a href="/digital-marketing" onClick={() => playPageSwitch('Digital Marketing')}>
                                        Digital Marketing
                                    </a>
                                </div> */}

                            </div>
                            <div className="pages">
                                <h3>Pages</h3>
                                {/* <div className="page">
                                    <Link
                                        onClick={() => playPageSwitch('Student\'s Life')}

                                        to="/students-life">Student's Life</Link>
                                </div>
                                <div className="page">
                                    <Link
                                        onClick={() => playPageSwitch('Contact')}
                                        to="/contact">Contact</Link>
                                </div>
                                <div className="page">
                                    <Link
                                        onClick={() => playPageSwitch('About')}
                                        to="/about">About</Link>
                                </div> */}

                                <div className="page">
                                    <a href="/students-life" onClick={() => playPageSwitch("Student's Life")}>Student's Life</a>
                                </div>
                                <div className="page">
                                    <a href="/contact" onClick={() => playPageSwitch("Contact")}>Contact</a>
                                </div>
                                <div className="page">
                                    <a href="/about" onClick={() => playPageSwitch("About")}>About</a>
                                </div>
                                <div className="page">
                                    <a href="/careers/business-development" onClick={() => playPageSwitch("Recruitment")}>Recruitment</a>
                                </div>
                                {/* <div className="page">
                                    <a href="/hire-from-us" onClick={() => playPageSwitch("HireFromUs")}>HireFromUs</a>
                                </div> */}
                                <div className="page">
                                    <a href="/paynow" onClick={() => playPageSwitch("Paynow")}>Paynow</a>
                                </div>
                                
                            </div>
                            <div className="contact">
                                <div className="container">
                                    <h3>Contact Us</h3>
                                    <div className="card">
                                        <a href="tel:+917036388881">
                                            <img src="/global/icons/phone-white.webp" alt="" />
                                            +91 70363 88881
                                        </a>
                                    </div>
                                    <div className="card">
                                        <a href="mailto:enquire@hashproacademy.com">
                                            <img
                                                style={{ filter: "brightness(0) invert(1)" }}
                                                src="/global/icons/mail.webp"
                                                alt=""
                                            />
                                            enquire@hashproacademy.com
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div className="container">
                                    <h3>Profile</h3>
                                    <div className="page">
                                        <AuthProvider>
                                            <SignUpSwitch />
                                        </AuthProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav bg-[#131313] py-6 px-0 transition-all">
                        <input type="checkbox" id="nav-check" />
                        {/* <Link
                            onClick={() => playPageSwitch('HashPro Academy <br/> (Home)')}

                            className="logo-img-container" to="/">
                            <img
                                loading="eager"
                                className="logo-img"
                                width="160px"
                                src="/global/logos/HashProAcademy.png"
                            />
                        </Link> */}
                        <a href="/" className="logo-img-container">
                            <img
                                loading="eager"
                                className="logo-img"
                                width="160px"
                                src="/global/logos/hashpro-white.webp"
                                alt="HashPro Academy Logo"
                            />
                        </a>

                        <div className="refs">
                            {/* <Link
                                className='close-after-click'
                                onClick={() => playPageSwitch('About Us')}
                                to='/about'>About</Link>

                            <Link

                                className='close-after-click'
                                onClick={() => playPageSwitch('Programs')}
                                to='/programs'>Programs</Link>
                            <a href='https://www.instagram.com/thehashproacademy/'>Instagram</a>
                            <Link
                                className='close-after-click'

                                onClick={() => playPageSwitch('Instagram')}

                                to='https://www.instagram.com/thehashproacademy/'>Recruitment</Link>

                            <Link
                                onClick={() => playPageSwitch('Contact')}
                                to='/contact'>Contact</Link> */}
                            {/* <BubblyLink to='/about'>About</BubblyLink> */}

                            <a href="/about" className="close-after-click">About</a>

                            <a href="/students-life" className="close-after-click">Student's Life</a>


                            <a href="/careers/business-development/" className="close-after-click">Recruitment</a>

                            <a href="/contact">Contact</a>

                            <a style={{ padding: '2px', height: 'fit-content' }} className="w-fit p-0 mx-2" target="_blank" href="https://www.instagram.com/thehashproacademy/">
                                <img className="w-6 block my-auto" src="/global/icons/instagram.webp" alt="" />  </a>

                        </div>

                        {location.pathname == '/digital-marketing-xperential-bootcamp' &&
                            <a className="block mr-16 sm:hidden" href="#lead-form-redirect">
                                <button class="lead-form-open" id="lead-form-open" >Apply Now</button>
                            </a>
                        }
                        <button onClick={() => MasterClassOptions.switchMasterClassOpen()} className="black mr-16 dmx-masterclass-book-mobile">
                            Book
                        </button>
                        <div className="nav-btn">

                            <label htmlFor="nav-check">
                                <span />
                                <span />
                                <span />
                            </label>
                        </div>
                        <div className="final">


                            <button onClick={() => MasterClassOptions.switchMasterClassOpen()} className="black mr-3 dmx-masterclass-book-desktop">
                                Book
                            </button>
                            <div id="explore-courses" className="explore-courses">
                                Explore <img loading="eager" src="/global/icons/dropdown.png" />
                            </div>

                            {location.pathname == '/digital-marketing-xperential-bootcamp'
                                ?
                                <a href="#lead-form-redirect">
                                    <button class="lead-form-open" id="lead-form-open" >Apply Now</button>
                                </a>
                                :
                                <div className="contact-now">
                                    <a href="tel:+917036388881">
                                        <img loading="eager" src="/global/icons/phone-white.webp" />
                                        {/* Contact Now */}
                                    </a>
                                </div>
                            }

                        </div>
                        <div className="nav-links">
                            <div id="courses-view" className="courses-desktop mobile">
                                <div className="grid">
                                    <div className="coursess">
                                        <h3>Courses</h3>
                                        <div className="course">
                                            {/* <img src="/global/icons/arrow.webp" alt=""> */}
                                            <a href="/art-of-influence/">Art Of Influence</a>
                                        </div>
                                        <div className="course">
                                            {/* <img src="/global/icons/arrow.webp" alt=""> */}
                                            <a href="/digital-marketing-xperential-bootcamp">
                                                Digital Marketing Xperential Bootcamp
                                            </a>
                                        </div>
                                        {/* <div className="course">
                                            <a href="">Digital Marketing</a>
                                        </div> */}
                                    </div>
                                    <div className="pages">
                                        <h3>Pages</h3>
                                        {/* <div className="page">
                                            <Link
                                                className='close-after-click'

                                                onClick={() => playPageSwitch('Student\'s Life')}
                                                to="/students-life">Student's Life</Link>
                                        </div>
                                        <div className="page">
                                            <Link
                                                className='close-after-click'

                                                onClick={() => playPageSwitch('Contact')}
                                                to="/contact">Contact</Link>
                                        </div>
                                        <div className="page">
                                            <Link
                                                className='close-after-click'

                                                onClick={() => playPageSwitch('About')}
                                                to='/about'>About</Link>
                                        </div> */}
                                        <div className="page">
                                            <a href="/students-life" className='close-after-click' onClick={() => playPageSwitch("Student's Life")}>Student's Life</a>
                                        </div>
                                        <div className="page">
                                            <a href="/contact" className='close-after-click' onClick={() => playPageSwitch("Contact")}>Contact</a>
                                        </div>
                                        <div className="page">
                                            <a href="/about" className='close-after-click' onClick={() => playPageSwitch("About")}>About</a>
                                        </div>
                                        <div className="page">
                                            <a href="/careers/business-development" onClick={() => playPageSwitch("Recruitment")}>Recruitment</a>
                                        </div>
                                        {/* <div className="page">
                                    <a href="/hire-from-us" onClick={() => playPageSwitch("HireFromUs")}>HireFromUs</a>
                                </div> */}
                                <div className="page">
                                    <a href="/paynow" onClick={() => playPageSwitch("Paynow")}>Paynow</a>
                                </div>

                                    </div>
                                    <div className="">
                                        <div className="container">
                                            <h3>Profile</h3>
                                            <div className="page">
                                                <AuthProvider>
                                                    <SignUpSwitch />
                                                </AuthProvider>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="contact">
                                        <div className="container">
                                            <h3>Contact Us</h3>
                                            <div className="card">
                                                <a href="tel:+917036388881">
                                                    <img src="/global/icons/phone-white.webp" alt="" />
                                                    +91 70363 88881
                                                </a>
                                            </div>
                                            <div className="card">
                                                <a href="mailto:enquire@hashproacademy.com">
                                                    <img
                                                        style={{ filter: "brightness(0) invert(1)" }}
                                                        src="/global/icons/mail.webp"
                                                        alt=""
                                                    />
                                                    enquire@hashproacademy.com
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="navbar-marquee">
                    <p>
                        &nbsp;• Best Offline Courses in Hyderabad • Get Yourself a Pro Tag •
                        Hashpro Academy
                    </p>
                    <p>
                        &nbsp;• Best Offline Courses in Hyderabad • Get Yourself a Pro Tag •
                        Hashpro Academy
                    </p>
                    <p>
                        &nbsp;• Best Offline Courses in Hyderabad • Get Yourself a Pro Tag •
                        Hashpro Academy
                    </p>
                    <p>
                        &nbsp;• Best Offline Courses in Hyderabad • Get Yourself a Pro Tag •
                        Hashpro Academy
                    </p>
                    <p>
                        &nbsp;• Best Offline Courses in Hyderabad • Get Yourself a Pro Tag •
                        Hashpro Academy
                    </p>
                </div> */}
            </div >

            }

        </>

    )



}
