import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useParams } from "react-router-dom";

export default function ThankYou() {

    const params = useParams();

    const [fromPage, setFromPage] = useState('')


    useEffect(() => {
        setFromPage(params.from)
    })



    return (
        <>
            <Helmet>
                <title>{'Thank You'}</title>
                <meta name="description" content="Thank You" />
                <meta property="og:title" content="Your Open Graph Title" />
                <meta property="og:description" content="Your Open Graph Description" />
                <meta property="og:image" content="URL to Your Image" />
                <meta property="og:url" content="URL to Your Web Page" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Your Site Name" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:title" content="Your Twitter Title" />
                <meta name="twitter:description" content="Your Twitter Description" />
                <meta name="twitter:image" content="URL to Your Image" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <section className="pt-32 relative h-[100vh] pb-24 text-center">
                <div className="m-auto w-fit h-fit  absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <h2 className="text-3xl sm:text-5xl text-hashpro">THANK YOU!</h2>
                    <h3 className="text-lg sm:text-2xl font-medium">FOR CONTACTING US</h3>
                    <h5 className="text-sm sm:text-lg mt-6 text-hashpro">WE WILL BE IN TOUCH VERY SOON</h5>

                    {
                        fromPage == 'dmx' &&
                        <a className="block mx-auto mt-12" href="/global/dmx/DMX-Brochure.pdf">
                            <button class="mx-auto yellow flex gap-3">
                                <img src="/global/icons/courses.webp" className="invert w-5 my-auto" alt="admissions" />
                                <p>Download the Brochure </p>
                            </button>
                        </a>
                    }

                    <Link className="mx-auto block mt-12" to='/'>
                        <button className="yellow mx-auto">◀ Go Back To Home Page</button>
                    </Link>

                </div>
            </section >
        </>
    )
}