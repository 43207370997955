import { containerClasses } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PrintReceipt from "./PrintReceipt";
import { getAuth } from "firebase/auth";
import { useAuth } from "../../Providers/AuthProvider";


export default function Receipt() {

    const { currentUser } = useAuth()
    const params = useParams()
    // console.log(params)

    const [orderData, setOrderData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        console.log(orderData)
    }, [orderData])


    async function fetchPayment() {
        await axios.post(process.env.REACT_APP_SERVER_URL + '/payments/status', {
            order_id: params.orderid,
            reference_no: params.referenceno,
            user_email: currentUser.email
        })
            .then(function (response) {
                console.log(response);
                setOrderData(response.data)
            })
            .catch(function (error) {
                console.log(error);
            });
        setLoading(false)
    }

    useEffect(() => {
        fetchPayment()
    }, [])

    useEffect(() => {
        console.log(orderData)
    }, [orderData])

    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        documentTitle: `Hashpro Academy ${orderData.order_id}`,
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => console.log("after printing..."),
        removeAfterPrint: true,
    });

    return (
        <>
            <section className="mt-52 mb-16">
                {loading ? <p>Loading...</p>
                    :
                    <>
                        <h2 className="text-3xl font-semibold  ">Transaction Result</ h2>
                        {(typeof orderData === 'object') && (Object.keys(orderData).length !== 0 && orderData?.status_message != undefined)
                            ?
                            <>
                                <div className="mt-6 ">
                                    <div className="flex flex-col gap-4">
                                        <div className="flex">
                                            < h5>Order ID : &nbsp;</ h5>
                                            <p>{orderData.order_id}</p>
                                        </div>
                                        <div className="flex">
                                            < h5>Name : &nbsp;</ h5>
                                            <p>{orderData.billing_name}</p>
                                        </div>
                                        <div className="flex">
                                            < h5>Phone : &nbsp;</ h5>
                                            <p>{orderData.billing_tel}</p>
                                        </div>
                                        <div className="flex">
                                            < h5>Email : &nbsp;</ h5>
                                            <p>{orderData.billing_email}</p>
                                        </div>
                                        <div className="flex">
                                            < h5>Transaction Status : &nbsp;</ h5>
                                            <p>{orderData.order_status == 'Success' ? <span className="text-[#16a34a]">Success</span>
                                                : orderData.order_status == 'Failure' ? <span className="text-[#df2424]">Failure</span>
                                                    : orderData.order_status == 'Aborted' && <span className="text-[#df2424]">Aborted, {orderData.status_message}</span>

                                            }</p>
                                        </div>
                                        <div className="flex">
                                            <h5> Amount : &nbsp;</ h5>
                                            <p>{orderData.amount}</p>
                                        </div>

                                        <div className="flex">
                                            < h5>Transaction Date : &nbsp;</ h5>
                                            <p>{orderData.trans_date}</p>
                                        </div>

                                        <div>
                                            <button onClick={() => {
                                                handlePrint(null, () => contentToPrint.current);
                                            }} className="yellow text-sm py-2 px-6 mt-4">Print Receipt</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-1 h-1 overflow-hidden">
                                    <PrintReceipt ref={contentToPrint} data={orderData} />
                                </div>
                            </>
                            :
                            <>

                                <h5>{orderData.message == 'Unauthorised Access' ? 'Unauthorised Access' : "Payment Tampered or Failed"}</ h5>
                            </>
                        }
                    </>
                }
            </section >

        </>
    )
}