// src/IconCloudDemo.js
import React from 'react';
import IconCloud from './IconCloud';

const slugs = [
  'apple',
  'microsoft',
  'google',
  'amazon',
  'facebook',
  'ibm',
  'samsung',
  'intel',
  'oracle',
  'adobe',
  'sony',
  'cisco',
  'hp',
  'dell',
  'lenovo',
  'sap',
  'siemens',
  'panasonic',
  'huawei',
  'xerox',
  'twitter',
  'netflix',
  'ebay',
  'paypal',
  'tesla',
  'nvidia',
  'zoom',
  'spotify',
  'salesforce',
  'vmware',
  'atlassian',
  'servicenow',
  'stripe',
  'tcs', // Tata Consultancy Services
  'walmart',
  'unilever',
  'procter-gamble',
  'nestle',
  'bp', // British Petroleum
  'shell',
  'exxonmobil',
  'total',
  'chevron',
  'goldman-sachs',
  'morgan-stanley',
  'barclays',
  'hsbc',
  'jpmorgan',
  'ubs',
  'rbc', // Royal Bank of Canada
];

const IconCloudDemo = () => {
  return (
    <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-lg border bg-background px-5 pb-10 pt-8">
      <IconCloud iconSlugs={slugs} className="w-[80px]" />
    </div>
  );
};

export default IconCloudDemo;
