import gsap, { CustomEase, ScrollTrigger } from "gsap/all"


const playPageSwitch = (text) => {

    // try {
    //     const transitionText = document.getElementById('page-transition-text')
    //     transitionText.innerHTML = text

    // } catch (e) {
    //     // alert(e)
    // }
    // gsap.registerPlugin(ScrollTrigger)

    // // document.getElementById('body-container').style.opacity = '0'
    // gsap.timeline().to('#page-transition-container', {
    //     duration: 0.5,
    //     left: 0,
    //     ease: "circ.inOut",
    // }, 0).to('#page-transition-container', {
    //     delay: 0.3,
    //     duration: 0.5,
    //     ease: "circ.inOut",
    //     left: '105%',
    //     onComplete: function () {
    //         document.getElementById('page-transition-container').style.left = '-105%'
    //     }
    // }, 1).to('#body-container', {
    //     delay: 0.3,
    //     duration: 0.5,
    //     ease: "power1.out",
    //     opacity: '1',
    // }, 1)
    // console.log('done')

    // gsap.timeline().to('#page-transition-container', {
    //     duration: 2,
    //     left: '110%',
    //     ease: "slow(0.3,0.4,false)",
    //     onComplete: function () {
    //         document.getElementById('page-transition-container').style.left = '-105%'
    //     }
    // }, 1)

}

export default playPageSwitch