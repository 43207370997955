import { useState, useEffect } from "react";
import { useTestContext } from "../../../Providers/HATProvider";

export default function TestProgress() {
    const { percentage } = useTestContext();

    return (

        <div>
            <p className="text-xs">Your Progress </p>
            <p>{percentage}% Complete</p>

            <div className="h-3 w-[20vw] bg-gray-500 rounded-xl overflow-hidden">
                <div style={{ width: `${percentage}%` }} className="transition-all h-full bg-hashpro"></div>
            </div>
        </div>

    );
}
