import { useEffect, useState } from "react"
import { deleteEmail, fetchStudentMails } from "./adminFunctions"
import { useResolvedPath } from "react-router-dom"
import { icons } from "../../../Global_Components/Icons"
import { Button, Tooltipm, Dialog, DialogActions, DialogTitle, Tooltip } from "@mui/material"
import Loader from "../../../Global_Components/Loader"




export default function MailOperations() {

    const [studentMails, setStudentMails] = useState()
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [mailID, setMailID] = useState('')
    const [mailToDelete, setMail] = useState('')

    console.log(studentMails)

    async function removeEmail() {
        const res = await deleteEmail(mailID)
        if (res) {
            handleDeleteClose()
            hideDeletedMail()
            alert('Mail Deleted')


        } else {
            alert('Delete Mail Error')
        }
    }

    function hideDeletedMail() {

    }

    async function getStudentMails() {
        const mails = await fetchStudentMails()
        setStudentMails(mails)

    }

    useEffect(() => {
        getStudentMails()
    }, [])


    const handleDeleteOpen = () => {
        setDeleteDialogOpen(true);
    };

    const handleDeleteClose = () => {
        setDeleteDialogOpen(false);
    };

    return (
        <>
            <h1 className="text-2xl text-center py-6">Manage Student Emails</h1>
            <div className="px-12">
                {studentMails != undefined ? studentMails.map((mail) => {
                    console.log(studentMails.length)
                    return (
                        <div key={mail.id} className="flex ">
                            <div className=" flex my-2 w-fit bg-slate-200 rounded-xl">
                                <p className="text-black p-4 ">{mail.email}</p>
                                <div className="my-auto">
                                    <img onClick={() => { setMailID(mail.id); setMail(mail.email); handleDeleteOpen() }} className="w-10 h-10 cursor-pointer" src={icons.DeleteBlack} />
                                </div>
                            </div>
                        </div>
                    )
                }) : <h1><Loader /></h1>
                }
            </div>
            <Dialog
                open={deleteDialogOpen}
                onClose={handleDeleteClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description" >
                <DialogTitle id="alert-dialog-title">
                    {`Are you sure you want to delete ? `}
                    <br />
                    {mailToDelete}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={handleDeleteClose}>No</Button>
                    <Button onClick={removeEmail} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

}