export default function ShippingAndDeliveryPolicy() {

    return (


        <section>
            <div className="policy-page-container">
                <div className="policy-heading">
                    <h1>Shipping &amp; Delivery Policy</h1>
                </div>
                <div className="policy-body">
                    <p>
                        Thank you for choosing HashPro Academy. This Shipping and Delivery Policy
                        outlines the terms and conditions regarding the shipping and delivery of
                        any physical materials associated with our courses.
                        <br />
                        <h2> 1. Shipping Information</h2>
                        a. Physical Materials: Certain courses may include physical materials such
                        as textbooks, workbooks, or other learning resources.
                        <br />
                        <br />
                        b. Shipping Address: Please ensure that the shipping address provided
                        during the course registration process is accurate and complete. HashPro
                        Academy is not responsible for items shipped to an incorrect address
                        provided by the participant.
                        <br />
                        <br />
                        c. Delivery Partners: We use reputable courier services to deliver
                        physical materials. The choice of the courier may vary based on the
                        destination.
                        <br />
                        <h2> 2. Delivery Timeframes</h2>

                        a. Course Materials Dispatch: Physical materials will be dispatched within
                        30 days from the date of course enrollment.
                        <br />
                        <br />
                        b. Estimated Delivery: The estimated delivery time may vary based on the
                        participant's location. Participants will be provided with an estimated
                        delivery timeframe upon course registration.
                        <br />
                        <h2> 3. Tracking and Notifications</h2>

                        a. Tracking Information: Participants will receive tracking information
                        for their shipped items, allowing them to monitor the delivery status.
                        <br />
                        <br />
                        b. Delivery Notifications: Participants may receive notifications via
                        email or SMS regarding the status of their delivery.
                        <br />
                        <h2> 4. Delivery Charges</h2>

                        a. Included in Course Fee: The cost of shipping and delivery is included
                        in the course admission fee for courses that include physical materials.
                        <br />
                        <h2> 5. International Shipments</h2>

                        a. Customs and Import Duties: For international shipments, participants
                        are responsible for any customs or import duties that may apply in their
                        respective countries.
                        <br />
                        <h2> 6. Delivery Issues</h2>

                        a. Delayed Delivery: While we make every effort to ensure timely delivery,
                        HashPro Academy is not responsible for delays caused by unforeseen
                        circumstances, including but not limited to customs delays, natural
                        disasters, or other external factors.
                        <br />
                        <br />
                        b. Damaged or Lost Items: In the rare event of damaged or lost items
                        during transit, please contact us immediately at
                        [enquire@hashproacademy.com] for assistance.
                        <br />
                        <h2> 7. Contact Information</h2>

                        If you have any questions or concerns regarding our Shipping and Delivery
                        Policy, please contact us at
                        [enquire@hashproacademy.com](mailto:enquire@hashproacademy.com). By
                        enrolling in courses that include physical materials, you acknowledge and
                        agree to abide by the terms and conditions of this Shipping and Delivery
                        Policy. HashPro Academy reserves the right to modify this policy at any
                        time, and any changes will be effective immediately upon posting on our
                        website.
                    </p>
                </div>
            </div>

        </section>
    )
}