import SaturdayCalendar from "../../Global_Components/SaturdayCalendar";

export default function CalendarPage() {

    return (
        <>
            <div className=" flex flex-col-reverse gap-y-4 md:grid grid-cols-2 bg-[#101010]">
                <div className="md:scale-1 md-translate-x-0 w-fit mx-auto">
                    <p className="text-center text-xl font-bold mb-3">Pick A Date To Book Your Slot</p>
                    <SaturdayCalendar />
                </div>
                <div className="h-fit my-auto mx-4">
                    <h3 className="text-3xl sm:text-5xl text-hashpro ">DMX MASTERCLASS</h3>

                    <p>
                        A
                        <span className="text-hashpro"> masterclass </span>
                        that happens every <i> Weekend</i>
                    </p>

                    <p className="mt-6 font-semibold">Select A Date To Register Now!</p>
                    <p className="text-xs text-hashpro">*Available Only On Saturdays</p>

                </div>
            </div>
        </>
    )

}