import { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "../../../Utils/Toast";
import Loader from "../../../Global_Components/Loader";
import sections from "./sections";
import { useTestContext } from "../../../Providers/HATProvider";
import HATUtils from "../../../Utils/HATUtils";

import hat from '../styles/hat.module.css'




export default function PageSwitcher() {

    const { sectionID, pageID, setSectionAndPage, globalQuestions } = useTestContext();

    const [boundaries, setBoundaries] = useState([0, sections[sectionID].pages.length])
    const [sectionBoundaries, setSectionBoundaries] = useState([0, sections.length - 1])
    const [notAnsweredCount, setNotAnsweredCount] = useState(0)


    const [disablePrev, setDisablePrevious] = useState(true)
    const [disableNext, setDisableNext] = useState(false)
    const [uploadingLoading, setUploadingLoading] = useState(false)
    const [lastPage, setLastPage] = useState(-1)
    const [lastSection, setLastSection] = useState(-1)

    const [isVisible, setIsVisible] = useState(false); //popup for submit

    useEffect(() => {
        setNotAnsweredCount(parseInt(checkAnswers(globalQuestions)))
    }, [setIsVisible])



    const handleOK = () => {
        setIsVisible(false);
        submitAnswers()
        console.log('OKAYYYYYYYYYYYYYYYYYYYYY')
    };

    const handleCancel = () => {
        setIsVisible(false);
        console.log('NOOOOOOOOOOOO')
    };


    useEffect(() => {
        const lastSectionIndex = globalQuestions.length - 1;
        const lastPageIndex = globalQuestions[lastSectionIndex].pages.length - 1;
        setLastSection(lastSectionIndex)
        setLastPage(lastPageIndex)

        setNotAnsweredCount(parseInt(checkAnswers(globalQuestions)))

    }, [])

    useEffect(() => {
        calculateBoundaries()
        console.log(lastSection, lastPage, sectionID, pageID)
    }, [sectionID, pageID])

    useEffect(() => {
        resetButton()

    }, [sectionID])

    useEffect(() => {
        disableButtons()

    }, [boundaries])

    function disableButtons() {
        console.log(pageID == boundaries[1], sectionID == sectionBoundaries[1]);
        console.log(pageID, boundaries[1], ' : ', sectionID, sectionBoundaries[1]);
        if (pageID == boundaries[1] && sectionID == sectionBoundaries[1]) {


            setDisableNext(true)
        } else {
            setDisableNext(false)
        }

        if (pageID == boundaries[0] && sectionID == sectionBoundaries[0]) {
            setDisablePrevious(true)
        } else {
            setDisablePrevious(false)
        }

    }


    function calculateBoundaries() {
        const upper = sections[sectionID].pages.length - 1
        setBoundaries([0, upper])
    }

    function resetButton() {
        setDisablePrevious(true)
        setDisableNext(false)
    }

    function handleNext() {

        if (pageID == boundaries[1]) {
            setSectionAndPage(sectionID + 1, 0)

        } else
            setSectionAndPage(sectionID, pageID + 1)
    }

    function handlePrevious() {
        if (pageID == boundaries[0]) {
            setSectionAndPage(sectionID - 1, boundaries[1])

        } else
            setSectionAndPage(sectionID, pageID - 1)
    }
    function checkAnswers(sections) {
        let result = [];
        let answeredCount = 0

        sections.forEach(section => {
            section.pages.forEach(page => {
                page.questions.forEach(question => {
                    const answered = question.answer !== '' ? 'Y' : 'N';
                    if (answered == 'Y') answeredCount++
                    result.push({
                        number: question.number,
                        answered: answered
                    });
                });
            });
        });

        return result.length - answeredCount;
    }

    async function submitAnswers() {

        setUploadingLoading(true)
        const res = await HATUtils.getAnswers(globalQuestions)

        setUploadingLoading(false)

        if (res) {
            // window.location.href = '/results';
            window.location.replace('/hat/thank-you')

            notifySuccess('Test Submitted')
        } else {
            notifyError('Error Occurred While Submitting, Please Try Again!')
        }
    }

    async function handleSubmit() {


        const timerSubmit = localStorage.getItem("timerSubmit");
        if (timerSubmit == 'true') {
            submitAnswers()
        }
        else {

            if (notAnsweredCount > 0) {

                setIsVisible(true)


            } else {
                submitAnswers()
            }
        }
    }

    const alertStyles = {
        position: 'fixed',
        top: isVisible ? '50%' : '200%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        border: '1px solid black',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '5px 5px var(--hashpro)',
        transition: 'top 0.5s ease',
        zIndex: 9999,
    };
    return (
        <>

            <div className="flex  bottom-[3rem] gap-4 right-[5%] items-center">
                <button id="submit-test-hashpro-assessment-test" className="black h-fit my-auto" onClick={() => handleSubmit()}>{uploadingLoading ? <div className="flex gap-x-4  "> <span className="block my-auto"> Submitting...</span> <div className="w-6 "> <Loader /></div></div> : 'Submit Test'}</button>


                <button className={`${disablePrev && 'opacity-[0.3] hover:bg-hashpro'} yellow-black my-auto block`} disabled={disablePrev} onClick={() => handlePrevious()} >Previous</button>

                {/* <button className='null my-auto block' disabled={disablePrev} onClick={() => handlePrevious()}><img className={`${disablePrev && 'opacity-[0.3]'} h-8 w-8 my-auto transition-all rounded-[50%] hover:bg-hashpro`} src="/icons/left-arrow.png" alt="" /></button> */}
                {/* <button className='null my-auto block' disabled={disableNext} onClick={() => handleNext()} > <img className={`${disableNext && 'opacity-[0.3]'} h-8 w-8 my-auto transition-all rounded-[50%] hover:bg-hashpro`} src="/icons/right-arrow.png" alt="" /></button> */}
                <button className={`${disableNext && 'opacity-[0.3] hover:bg-hashpro'}  yellow-black my-auto block`} disabled={disableNext} onClick={() => handleNext()} >Next</button>
                {/* <button style={{ display: `${lastPage == pageID && lastSection == sectionID ? 'block' : 'none'}` }} id="submit-test-hashpro-assessment-test" className="yellow h-fit my-auto" onClick={() => handleSubmit()}>{uploadingLoading ? <div className="flex gap-x-4  "> <span className="block my-auto"> Submitting...</span> <div className="w-6 invert"> <Loader /></div></div> : 'Submit Test'}</button> */}

            </div>

            <div style={alertStyles}>
                <div className="text-xl font-semibold mb-8">{`You have not answered ${notAnsweredCount} questions, Are you sure you want to submit ?`}</div>
                <div className="w-fit ml-auto flex gap-4">
                    <button className="black" onClick={handleOK}>Yes, Submit</button>
                    <button className="black" onClick={handleCancel}>Cancel</button>
                </div>
            </div>
        </>
    )
}