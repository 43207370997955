const GlobalUtils = {
    uid10: () => {
        const length = 10
        const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        let result = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            result += characters.charAt(randomIndex);
        }

        return result;
    },
    getCurrentDateTime: () => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();

        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    },

    convertToDDMMYYYY: (dateInput) => {
        let dateObj;

        if (typeof dateInput === 'string') {
            // Try to parse the input string into a Date object
            dateObj = new Date(dateInput);
            if (isNaN(dateObj.getTime())) {
                throw new Error("String date format should be 'YYYY-MM-DD' or a valid date string");
            }
        } else if (dateInput instanceof Date) {
            dateObj = dateInput;
        } else {
            throw new TypeError("Input should be a string or a Date object");
        }

        const day = String(dateObj.getDate()).padStart(2, '0');
        const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = dateObj.getFullYear();

        return `${day}-${month}-${year}`;
    }

}

export default GlobalUtils