import styles from './Placement.module.css'
import HiringImage from './images/hiring-hero.png'
import JobCard from './Components/JobCard'
import Footer from '../../Global_Components/Footer'
import { useEffect, useState } from 'react'
import { makeRequest } from './scripts'
import { Button } from '@mui/material'
import Header from '../../Global_Components/Header'
import RenderPlacements from './Components/RenderPlacements'

export default function Placement() {

    return (
        <>
            {/* <Header /> */}
            <div className='pt-24'>
                <section>
                    <div className='p-2 py-20 grid grid-cols-1 md:grid-cols-2 gap-y-12'>
                        <div className='m-auto'>
                            <h1 className='text-4xl font-bold'>Placements Around The City</h1>
                            <p className="text-light subheading-light">Nostrud ut laborum dolore adipisicing cillum reprehenderit ea anim cillum cillum.</p>
                        </div>
                        <div>
                            <img className='m-auto w-[100%] flex align-middle justify-center items-center ' src={HiringImage} />
                        </div>

                    </div>
                </section>
                {/* <Button variant='contained' onClick={() => { console.log(placementData) }}>Log Placement Data</Button> */}

                <div className='bg-[#e6e6e6] py-12'>
                    <h1 className='text-3xl font-bold mb-6 text-center'>Job Postings</h1>

                    <RenderPlacements />
                </div>
                {/* <Footer /> */}
            </div>
        </>

    )

}