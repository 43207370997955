import { Link } from "react-router-dom"
import { useEffect, useLayoutEffect, useState } from "react";
import { useTestContext } from "../../../Providers/HATProvider";
import sections from "./sections";

export default function SectionSwitcher() {

    const { sectionID, pageID, setSectionAndPage, resetSectionAndPage } = useTestContext();
    const [isOpen, setIsOpen] = useState(false);

    const handleMouseEnter = () => {
        setIsOpen(true);
    };

    const handleMouseLeave = () => {
        setIsOpen(false);
    };

    useLayoutEffect(() => {
        const sectionNames = document.querySelectorAll('.section_change')
        sectionNames.forEach((section, index) => {
            // if (index == sectionID)
            //     section.style.color = 'white'
            // else
            //     section.style.color = 'inherit'

        })
    }, [sectionID])

    function handleSectionSwitch(e) {
        const newSection = parseInt(e.target.getAttribute('data-name'))
        const newSectionName = (e.target.getAttribute('data-section'))
        setSectionAndPage(newSection, 0)
        document.querySelector('.dropdown-toggle').innerHTML = newSectionName + ' &nbsp;&nbsp;<span class="text-xs">▼</span>'
    }

    return (

        <div className="custom-dropdown  h-fit my-auto relative w-[20vw]" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <button className="px-4 py-1 dropdown-toggle yellow-black static h-fit my-auto w-full text-sm">Switch Section &nbsp;&nbsp;<span class="text-xs">▼</span></button>
            {isOpen && (
                <div className=" border-black border-solid flex flex-col gap-2 absolute w-full left-0 top-[100%] bg-white border-2 border-t-0 p-2">
                    {sections.map((section, index) => {
                        return (
                            <p key={index} className="hover:underline font-semibold section_change hover:cursor-pointer text-sm" data-name={index} data-section={section.name} onClick={handleSectionSwitch}>
                                {section.name}
                            </p>
                        );
                    })}
                </div>
            )}
        </div>


    )

}