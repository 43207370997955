import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import { useTestContext } from "../../Providers/HATProvider";
import { notifySuccess, notifyError } from "../../Utils/Toast";
import PageSwitcher from "./components/PageSwitcher";
import Timer from "./components/Timer";
import PlainQuestion from "./components/PlainQuestion";
import NoQuestionsAttended from "./components/NoQuestionsAttended";
import TestProgress from "./components/TestProgress";
import GodView from "./components/GodView";
import HATUtils from "../../Utils/HATUtils";
import sections from "./components/sections";
import SectionSwitcher from "./components/SectionSwitcher";
import ParagraphQuestion from "./components/ParagraphQuestion";
import MathQuestion from "./components/MathQuestion";

export default function HATTest() {

    const { sectionID, pageID, setSectionAndPage, resetSectionAndPage } = useTestContext();

    const [toRender, setRender] = useState({})
    const params = useParams()
    const navigate = useNavigate();
    const [renderLoading, setRenderLoading] = useState(true)

    const [isAttempted, setIsAttempted] = useState(false)
    const [isAttemptedLoading, setIsAttemptedLoading] = useState(true)

    function updateQuestion(sectionID, pageID) {
        setRender(sections[sectionID].pages[pageID])
        // console.log(toRender)
    }
    useEffect(() => {
        console.log('Rendered New Questions ')
        setRenderLoading(false)

    }, [toRender])

    async function redirectToHomepageIfAttempted() {
        const attempted = await HATUtils.isAttemptHAT()
        setIsAttemptedLoading(false)
        setIsAttempted(attempted)
        if (attempted) {
            notifyError('Cannot write test twice !')
        }
    }

    useEffect(() => {
        setRenderLoading(true)
        updateQuestion(sectionID, pageID)
        navigate(`/hat/test/${sectionID}/${pageID}`);

    }, [sectionID, pageID])

    useEffect(() => {
        redirectToHomepageIfAttempted()

        function handleContextMenu(event) {
            event.preventDefault();
            notifyError('Right Click is Disabled During Assessment')
        }

        window.addEventListener('contextmenu', handleContextMenu);

        const isUser = HATUtils.isLocalUserPresent()
        if (!isUser) window.location.replace('/')
        return () => {
            window.removeEventListener('contextmenu', handleContextMenu);
        };


    }, []);

    const TouchDisableStyles = {
        WebkitTouchCallout: 'none',  // iOS Safari
        WebkitUserSelect: 'none',    // Safari
        KhtmlUserSelect: 'none',     // Konqueror HTML
        MozUserSelect: 'none',       // Old versions of Firefox
        msUserSelect: 'none',        // Internet Explorer/Edge
        userSelect: 'none',          // Non-prefixed version
      };


    return (
        <>

            {
                isAttemptedLoading ? (
                    <p> Loading...</p >
                ) : isAttempted ? (
                    <p>Cannot attempt test twice. Please contact administrator.</p>
                ) : (
                    <section style={TouchDisableStyles} className=" pl-32 pr-0 bg-[#ffffff] text-black min-h-[100vh] max-h-[100vh]">
                        <GodView />
                        <div className="px-4 bg-gray-100 flex gap-3 justify-between stretch flex-[0_0_100%] py-4">
                            <div className="flex gap-3">
                                <img className="w-20 h-6 my-auto" src="/logos/hashpro-black.png" alt="" />{" "}
                                <h2 className="block h-fit my-auto text-sm font-medium"> | HashPro Assessment Test (HAT)</h2>
                            </div>
                            <Timer />
                            <SectionSwitcher />
                        </div>
                        <div className="h-full min-h-[100%]">
                            <div className="h-full flex flex-col justify-between">
                                <div className="pl-4 h-full px-0 flex flex-col mt-4">
                                    {toRender && !renderLoading ? (
                                        toRender.type === "paragraph" ? (
                                            <ParagraphQuestion paragraph={toRender.paragraph} mcqs={toRender.questions} />
                                        ) : toRender.type === "math" ? (
                                            <MathQuestion imgURL={toRender.imgURL} mcqs={toRender.questions} />
                                        ) : toRender.type === "plain" && (
                                            <PlainQuestion mcqs={toRender.questions} />
                                        )
                                    ) : (
                                        <p>Loading...</p>
                                    )}
                                </div>
                                <div className="mt-auto">
                                    <div className="bg-gray-100 absolute bottom-0 left-32 right-0 px-12 py-4 flex justify-between mt-auto">
                                        <NoQuestionsAttended />
                                        <TestProgress />
                                        <PageSwitcher />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                )
            }
        </>
    )

}