import { Link } from "react-router-dom"
import { useAuth } from "../Providers/AuthProvider"

export default function UserHeader() {

    const { currentUser } = useAuth()

    return (

        <>

            <div className="fixed fsf:top-[69px] ninehundred:top-[76px] top-[89px] bg-[#131313] left-0 right-0 z-[9997] border-solid border-[#303030] border-t">

                <section className=" flex justify-between gap-6 py-3 border-solid border-[#303030] border-b">
                    <div className="flex gap-2">
                        <Link className="font-semibold  text-sm my-auto hover:bg-[#202020] px-4 py-1 rounded-xl " to='/user/payment-history'>Payment History</Link>
                        <p className="my-auto">
                            |
                        </p>
                        <Link className="font-semibold  text-sm my-auto hover:bg-[#202020] px-2 py-2 rounded-xl " to='/payments'>Pay Now</Link>
                    </div>
                    <div className="flex gap-1">
                        <img className="my-auto w-7 invert" src="/icons/profile.png" alt="" />

                        <p className="my-auto text-sm">{currentUser.email}</p>
                    </div>
                </section>

            </div>
        </>
    )
}