import { useEffect, useState } from 'react';
import HATUtils from '../../Utils/HATUtils';

export default function HATDashboard() {

    const [tests, setTests] = useState([])
    const [loading, setLoading] = useState(true)


    async function fetchTests() {
        const fetchedTests = await HATUtils.fetchAllTests()
        console.log('fetchedTests :', fetchedTests);

        if (fetchedTests == 'No Document Found') {
            alert('No Doc Found, Please contact developer')
        }

        setTests([...fetchedTests])
        setLoading(!loading)
    }
    useEffect(() => {
        fetchTests()
    }, [])

    return (
        <section className="pt-12">
            <h1 className="text-3xl mb-12">All Tests</h1>

            {tests != [] || !loading ?
                tests.map((test) => {
                    return (
                        <>
                            <div className='bg-[#202020] p-6 rounded-xl mb-8'>
                                <p>Name : {test.name}</p>
                                <p>Email : {test.email}</p>
                                <p>Phone : {test.phone}</p>
                                {test.time !== null && test.time !== undefined && (
                                    <p>Time : {test.time}</p>
                                )}
                                {(test.sections != null || test.sections != undefined) && test.sections.sections.map((section) => {

                                    return (

                                        <div className="mt-6">
                                            <p className="underline">{section.name}</p>
                                            <p>{section.marks}/{section.total}</p>
                                            <p>Cutoff : {section.cutoff}</p>
                                            <p>Grade: {section.grade}</p>
                                        </div >

                                    )
                                })
                                }
                                {(test.sections != null || test.sections != undefined) ?
                                    <div className="mt-6">
                                        <p className="underline">Total</p>
                                        <p>{test.marks != undefined && test.sections.totalmarks != undefined ? `${test.marks}/${test.sections.totalmarks}` : null}</p>
                                        <p>{test.sections.totalcutoff != undefined ? `Cutoff : ${test.sections.totalcutoff}` : null}</p>
                                        <p>{test.sections.totalgrade != undefined ? `Grade : ${test.sections.totalgrade}` : null}</p>

                                    </div>

                                    :
                                    <p>Did Not Write Test</p>}

                            </div>
                        </>
                    )
                })
                :
                <p>Loading...</p>}
        </section>
    )

}