import { Button, TextField, Snackbar, Alert } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

export default function AddPlacement({ initialData }) {

    // if(initialData==undefined) console.log('dadsa')

    const [openSuccess, setOpenSuccess] = useState(false);
    const [openError, setOpenError] = useState(false);


    function showUploadSuccessMessage() {
        console.log('open')
        openSuccessSnackbar()
    }
    function showErrorMessage() {
        openErrorSnackbar()
    }

    let httpRequest;

    const url = "https://www.hashproacademy.com/placements/api/uploadPlacement.php";

    function makeRequest(data) {
        httpRequest = new XMLHttpRequest();
        if (!httpRequest) {
            alert('Giving up :( Cannot create an XMLHTTP instance');
            return false;
        }
        httpRequest.onreadystatechange = function () {
            if (httpRequest.readyState === 4) {
                if (httpRequest.status === 200) {
                    showUploadSuccessMessage()

                    console.log(httpRequest.responseText);
                } else {
                    showErrorMessage()

                    console.error("Error: " + httpRequest.status);
                }
            }
        };

        try {
            httpRequest.open('POST', url);
            httpRequest.send(data);
        } catch (e) {
            console.log(e);
        }

        setTimeout(() => {
            alertContents()

        }, 1500);
    }

    function alertContents() {
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
            console.log(httpRequest.responseText);
        }
    }

    const formik = useFormik({
        initialValues: {
            id: uuidv4(),
            jobTitle: '',
            company: '',
            jd: '',
            jobLocation: '',
            mail: '',
            website: '',
            buttonName: '',
            buttonLink: '',

        },
        onSubmit: values => {
            const randId = uuidv4()
            const formdata = new FormData();

            formdata.append('id', randId);
            formdata.append('jobTitle', values.jobTitle)
            formdata.append('company', values.company)
            formdata.append('jd', values.jd)
            formdata.append('jobLocation', values.jobLocation)
            formdata.append('mail', values.mail)
            formdata.append('website', values.website)
            formdata.append('buttonName', values.buttonName)
            formdata.append('buttonLink', values.buttonLink)
            formdata.append('reqType', 'add')

            makeRequest(formdata)

        },
    });

    const openSuccessSnackbar = () => {
        setOpenSuccess(true);
    };
    const openErrorSnackbar = () => {
        setOpenError(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccess(false);
        setOpenError(false)
    };


    return (
        <>
            <div className='px-8 py-32 '>
                <h1 className='text-center text-2xl py-6 font-semibold'>Add Placement</h1>
                {/* <Link className='text-black underline' to='manage'><Button variant='contained'>Manage Placements</Button></Link> */}
                <form onSubmit={formik.handleSubmit}>
                    <input placeholder='Job Title' className='w-full' label="Job Title" id='jobTitle' name='jobTitle' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.jobTitle} />
                    <br />
                    <br />

                    <input placeholder='Company Name' className='w-full' label="Company" id='company' name='company' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.company} />
                    <br />
                    <br />

                    <input placeholder='Job Description' className='w-full' multiline rows={5} label="Job Description" id='jd' name='jd' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.jd} />
                    <br />
                    <br />

                    <input placeholder='Job Location or Mode (Hybrid / Onsite / Remote)' className='w-full' label="Job Location" id='jobLocation' name='jobLocation' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.jobLocation} />
                    <br />
                    <br />

                    <input placeholder='Company Website' className='w-full' label="Company Website " id='website' name='website' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.website} />
                    <br />
                    <br />

                    <input placeholder='E-Mail' className='w-full' label="Mail Id " id='mail' name='mail' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.mail} />
                    <br />
                    <br />

                    <input placeholder='Custom Button Name' className='w-full' label="Change Button Name" id='buttonName' name='buttonName' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.buttonName} />
                    <br />
                    <br />
                    <input placeholder='Button Link (or) E-Mail' className='w-full' label="Button Link / Email" id='buttonLink' name='buttonLink' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.buttonLink} />
                    <br />
                    <br />
                    <button className='yellow' type="submit" variant="contained">Upload Placement</button>
                    <br />
                    <br />

                </form>
            </div>
            <Snackbar open={openSuccess} autoHideDuration={5000} onClose={handleClose}>
                <Alert className='bg-red' onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                    Placement Data has been uploaded
                </Alert>
            </Snackbar>
            <Snackbar open={openError} autoHideDuration={5000} onClose={handleClose}>
                <Alert className='bg-red' onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    Error, Please Try Submitting Again
                </Alert>
            </Snackbar>
        </>
    )
}