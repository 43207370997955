import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../Global_Components/Loader";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [formStatus, setFormStatus] = useState("default");

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    location: "",
    purpose: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    location: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Location is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),
    purpose: Yup.string().required("Please Select An Option"),
    message: Yup.string(),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setFormStatus("loading");
    const jsonData = JSON.stringify(values);

    fetch(`${process.env.REACT_APP_SERVER_URL}/forms/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setFormStatus("submitted");
        return response.text();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setFormStatus("failed");
      })
      .finally(() => {
        setSubmitting(false);
      });

    console.log(jsonData);
  };

  return (
    <>
      <Helmet>
        <title>{"Contact"}</title>
        <meta name="description" content="Contact" />
        <meta property="og:title" content="Your Open Graph Title" />
        <meta property="og:description" content="Your Open Graph Description" />
        <meta property="og:image" content="URL to Your Image" />
        <meta property="og:url" content="URL to Your Web Page" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Your Site Name" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:title" content="Your Twitter Title" />
        <meta name="twitter:description" content="Your Twitter Description" />
        <meta name="twitter:image" content="URL to Your Image" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <section className="contact-page pt-32 pb-24">
        <div className="text h-fit m-auto">
          <h2 className="text-4xl mb-2 text-left">Get in Touch</h2>
          <p>
            Have questions, feedback, or inquiries? Reach out to us – we're here
            to help!
            <br />
            <br />
            <a
              className="hover:underline text-hashpro"
              href="mailto:enquire@hashproacademy.com"
            >
              enquire@hashproacademy.com
            </a>
            <br />
            <a
              className="hover:underline text-hashpro"
              href="tel:+917036388881"
            >
              +91 70363 88881
            </a>
          </p>
        </div>
        <div className="grid md:grid-cols-2 gap-12 mt-12">
          <div
            style={{ position: "relative", width: "100%" }}
            className="h-fit my-auto lead-form"
            id="lead-form"
          >
            <div className="container">
              <div className="info">
                <h3>Hey there! Fill the details</h3>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting }) => (
                  <Form
                    id="contact-form"
                    className="dark h-fit my-auto  inputs-container flex flex-col gap-y-4"
                  >
                    <div>
                      <Field
                        id="name"
                        className="border-black"
                        placeholder="Enter Your Name"
                        type="text"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="email"
                        placeholder="Enter Your Email"
                        type="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="phone"
                        placeholder="Enter Your Phone No. "
                        type="tel"
                        name="phone"
                      />
                      <span className=" text-yellow-500 p-2 m-2">
                        {" "}
                        *Preferably WhatsApp Number
                      </span>
                      <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="location"
                        placeholder="Enter Your Location"
                        type="text"
                        name="location"
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>

                    <div>
                      <Field
                        as="select"
                        id="purpose"
                        name="purpose"
                        placeholder="Select Your Purpose"
                      >
                        <option selected value="" hidden>
                          Select Your Purpose
                        </option>
                        <option value="Digital Marketing Xperiential Bootcamp">
                          Digital Marketing Xperiential Bootcamp
                        </option>
                        <option value="Art Of Influence">
                          Art Of Influence
                        </option>
                      </Field>
                      <ErrorMessage
                        name="purpose"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        as="textarea"
                        name="message"
                        rows={3}
                        id="message"
                        placeholder="Your Query / Message"
                        type="text"
                      />
                    </div>
                    <div className="center">
                      <button
                        type="submit"
                        className="black submit-btn w-full"
                        disabled={
                          isSubmitting ||
                          formStatus == "loading" ||
                          formStatus == "submitted"
                        }
                      >
                        {formStatus === "loading" ? (
                          <Loader />
                        ) : formStatus === "failed" ? (
                          "Failed, Submit Again"
                        ) : formStatus === "submitted" ? (
                          <span>
                            {" "}
                            <span className="rounded-lg">
                              {" "}
                              Form Has Been Submitted
                            </span>
                            <br />{" "}
                            <span className="text-xs mt-1 inline-block">
                              {" "}
                              We Shall Contact You Soon
                            </span>
                          </span>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                    <p style={{ color: "black", fontSize: "0.8rem" }}>
                      Sign up to receive updates and notifications. We respect
                      your privacy and will not override your DND or NDNC
                      preferences.
                    </p>
                    <ErrorMessage
                      name="general"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className=" h-fit my-auto `">
            <div className="h-fit ">
              <iframe
                className="shadow-[3px_3px_#f8d00d] border-2 border-black rounded-xl my-auto w-full aspect-square block"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                id="gmap_canvas"
                src="https://maps.google.com/maps?width=500&amp;height=500&amp;hl=en&amp;q=3rd%20Floor,%20Barwale%20Chambers,%20Street%20Number%2011,%20Himayatnagar,%20Hyderabad,%20Telangana%20500029%20Hyderabad+(Hashpro%20Academy)&amp;t=k&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>{" "}
              <script
                type="text/javascript"
                src="https://embedmaps.com/google-maps-authorization/script.js?id=a077490f275082f40ad0cf599a87b96d7e69b8c1"
              ></script>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
