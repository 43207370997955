import { Button, TextField, Snackbar, Alert } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid'
import { useParams, useLocation } from 'react-router-dom';
import { editPlacement } from '../scripts';

export default function PlacementInputs({ data, type }) {

    const formdata = new FormData();


    const formik = useFormik({
        initialValues: {
            id: data != undefined ? data.id : uuidv4(),
            jobTitle: data != undefined ? data.jobTitle : '',
            company: data != undefined ? data.company : '',
            jd: data != undefined ? data.jd : '',
            jobLocation: data != undefined ? data.jobLocation : '',
            mail: data != undefined ? data.mail : '',
            website: data != undefined ? data.website : '',
            buttonName: data != undefined ? data.buttonName : '',
            buttonLink: data != undefined ? data.buttonLink : '',
        },

        onSubmit: values => {


            formdata.append('jobTitle', values.jobTitle)
            formdata.append('company', values.company)
            formdata.append('jd', values.jd)
            formdata.append('jobLocation', values.jobLocation)
            formdata.append('mail', values.mail)
            formdata.append('website', values.website)
            formdata.append('buttonName', values.buttonName)
            formdata.append('buttonLink', values.buttonLink)
            formdata.append('reqType', 'add')

            if (type == 'edit') {
                formdata.append('id', data.id);
                changePlacement()
            } else if (type == 'upload') {

                const randId = uuidv4()
                formdata.append('id', randId);


            }
        },
    });

    async function changePlacement() {
        const res = await editPlacement(formdata)
        if (res) {
            alert('Edit Successful')
            console.log(true)

        }
        else console.log(res)
    }

    return (
        <>
            <div className='px-10'>
                <form onSubmit={formik.handleSubmit}>
                    <TextField className='w-full' label="Job Title" id='jobTitle' name='jobTitle' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.jobTitle} />
                    <br />
                    <br />

                    <TextField className='w-full' label="Company" id='company' name='company' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.company} />
                    <br />
                    <br />

                    <TextField className='w-full' multiline rows={5} label="Job Description" id='jd' name='jd' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.jd} />
                    <br />
                    <br />

                    <TextField className='w-full' label="Job Location" id='jobLocation' name='jobLocation' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.jobLocation} />
                    <br />
                    <br />

                    <TextField className='w-full' label="Company Website " id='website' name='website' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.website} />
                    <br />
                    <br />

                    <TextField className='w-full' label="Mail Id " id='mail' name='mail' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.mail} />
                    <br />
                    <br />

                    <TextField className='w-full' label="Change Button Name" id='buttonName' name='buttonName' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.buttonName} />
                    <br />
                    <br />
                    <TextField className='w-full' label="Button Link / Email" id='buttonLink' name='buttonLink' variant="outlined" onChange={formik.handleChange}
                        value={formik.values.buttonLink} />
                    <br />
                    <br />
                    <Button type="submit" variant="contained">Upload Placement</Button>
                </form>
            </div>

        </>

    )
}