import { useEffect, useState, useRef } from "react";
import { useTestContext } from "../../../Providers/HATProvider";


export default function GodView() {

    const { globalQuestions, setSectionAndPage, godView, toggleGodView } = useTestContext()
    const [godViewData, setGodViewData] = useState([])

    useEffect(() => {
        const res = checkAnswers(globalQuestions)
        setGodViewData(res)
        console.log('res :', res);
    }, [globalQuestions])

    useEffect(() => {
        console.log('godView :', godView);

    }, [godView])

    const sectionRef = useRef(null);

    const handleMouseMove = (e) => {
        const section = sectionRef.current;
        if (!section) return;

        const { top, bottom, height } = section.getBoundingClientRect();
        const mouseY = e.clientY;
        const relativeY = mouseY - top;
        const scrollPercentage = relativeY / height;

        // Calculate the scroll position based on the mouse y position
        const maxScroll = section.scrollHeight - section.clientHeight;
        const scrollTo = maxScroll * (scrollPercentage);

        // Scroll the section to the calculated position
        section.scrollTop = scrollTo;
    };


    function findSectionAndRedirect(questionNumber) {
        let sectionIndex = -1;
        let pageIndex = -1;

        for (let i = 0; i < globalQuestions.length; i++) {
            const section = globalQuestions[i];
            for (let j = 0; j < section.pages.length; j++) {
                const page = section.pages[j];
                const foundQuestion = page.questions.find(question => question.number === questionNumber.toString());
                if (foundQuestion) {
                    sectionIndex = i;
                    pageIndex = j;
                    break;
                }
            }
            if (sectionIndex !== -1) {
                break;
            }
        }
        setSectionAndPage(sectionIndex, pageIndex)
    }

    function checkAnswers(sections) {
        let result = [];

        sections.forEach(section => {
            section.pages.forEach(page => {
                page.questions.forEach(question => {
                    const answered = question.answer !== '' ? 'Y' : 'N';
                    result.push({
                        number: question.number,
                        answered: answered
                    });
                });
            });
        });

        return result;
    }

    function goToQuestion(e) {
        const obj = e.currentTarget
        const number = obj.getAttribute('data-number')
        findSectionAndRedirect(number)
    }
    return (
        // onMouseMove={handleMouseMove} 
        <div ref={sectionRef} className=" p-0 w-32 absolute top-0 bottom-0 left-0 bg-gray-100  max-h-[100vh] overflow-hidden pt-4 pb-16">
            <h1 className="py-4 text-xs font-bold text-center">Answered</h1>
            {(godViewData != [] || godViewData != null) &&
                <div className="grid grid-cols-2 flex-col h-fit gap-4 justify-around w-fit mx-auto">

                    {godViewData.map((question) => {
                        return (
                            <div data-number={question.number} onClick={goToQuestion} className="hover:cursor-pointer flex gap-2 w-fit ">
                                <div style={{ backgroundColor: `${question.answered == 'Y' ? 'var(--hashpro)' : '#d4d4d4'}` }} className="w-4 h-4 rounded-[50%] my-auto"></div>
                                <p className="my-auto text-sm">{question.number}</p>
                            </div>
                        )
                    })}

                </div>
            }

        </div>

    )

}