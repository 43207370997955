import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create context for authentication
const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const auth = getAuth()

    useEffect(() => {

        onAuthStateChanged(auth, (user) => {
            setCurrentUser(user);
            setLoading(false);
        })

        // const unsubscribe = onAuthStateChanged(auth, (user) => {
        //     setCurrentUser(user);
        //     setLoading(false);
        // });

        // // Cleanup subscription
        // return unsubscribe;
    }, []);

    // Function to log in
    // const signIn = async (email, password) => {
    //     try {
    //         await firebase.auth().signInWithEmailAndPassword(email, password);
    //     } catch (error) {
    //         console.error(error.message);
    //     }
    // };

    // Function to log out
    const signOut = async () => {
        try {
            await auth.signOut();
        } catch (error) {
            console.error(error.message);
        }
    };

    // Provide authentication context values
    const authContextValue = {
        currentUser,
        // signIn,
        signOut
    };

    return (
        <AuthContext.Provider value={authContextValue}>
            {!loading && children}
        </AuthContext.Provider>
    );

};

// Custom hook to use auth context
export const useAuth = () => useContext(AuthContext);
