import React, { useLayoutEffect, useMemo } from "react";
import Countdown from 'react-countdown';
import { useTestContext } from "../../../Providers/HATProvider";
import HATUtils from "../../../Utils/HATUtils";
import { notifyError, notifySuccess } from "../../../Utils/Toast";
import Loader from "../../../Global_Components/Loader";

const timerRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
        localStorage.setItem("timerSubmit", true);

        HATUtils.submit()

        // notifyError('Time\s Up ⏲, Submitting Test')
        return <p id="timer-end-text" className="font-medium h-fit my-auto flex gap-3 bg-black text-white rounded-2xl px-4 py-2">Time's Up !<div className="w-6 "><Loader /></div>  </p>;

    } else {
        // Render a countdown
        return <div className="flex gap-2 bg-hashpro h-fit my-auto px-3 py-1 rounded-xl text-black"> <img className="w-4 h-4 block my-auto" src="/icons/timer.webp" alt="" /> <p className="my-auto h-fit font-medium text-sm">{minutes}min : {seconds}sec</p></div>;
    }
};
const CountdownWrapper = () => <Countdown date={Date.now() + (23 * 60 * 1000)} renderer={timerRenderer} />;

const MemoCountdown = React.memo(CountdownWrapper);

export default function Timer() {

    return (

        <MemoCountdown />

    )
}