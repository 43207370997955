import axios, * as others from 'axios';
import GlobalUtils from './GlobalUtils';

// const userID = 'HAT123'

function deleteAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}


function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


const HATUtils = {

    isAttemptHAT: async () => {
        const userID = getCookie('userid')

        console.log('userID :', userID);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/hat/hat-attempt?hatid=${userID}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
            console.log(response.data)
            return response.data

        } catch (error) {
            console.error('Error:', error.message);
        }
    },
    isLocalUserPresent: () => {
        const userID = getCookie('userid')
        if (userID == '') return false
        else return true
    },
    deleteLocalUser: () => {
        deleteAllCookies()
    },
    submit: () => {
        document.getElementById('submit-test-hashpro-assessment-test').click()
    },
    getResults: async () => {
        const userID = getCookie('userid')

        console.log('userID :', userID);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/hat/fetchResults?userid=${userID}`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            return response.data

        } catch (error) {
            console.error('Error:', error.message);
        }
    },

    getAnswers: async (jsonAnswers) => {
        const userID = getCookie('userid')

        console.log('userID :', userID);

        try {

            console.log(jsonAnswers);

            const finalData = {
                jsonAnswers: jsonAnswers,
                userID: userID,
                time: GlobalUtils.getCurrentDateTime()
            }

            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/hat/getAnswers`, {
                finalData,
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Handle the response
            console.log('Response:', response.data);
            // TestGlobalUtils.deleteLocalUser()
            return true

        } catch (error) {
            // Handle errors
            console.error('Error:', error.message);
            document.getElementById('timer-end-text').innerHTML = 'Error, Try Submitting Again'
            return false
            // throw error; // Rethrow the error for further handling or logging
        }

    },

    fetchAllTests: async () => {
    
        try {

            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/hat/fetch-all-tests`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                
            return response.data

        } catch (error) {
            console.error('Error Fetching All Tests:', error.message);
        }
    }
}

export default HATUtils


