export default function CancellationAndRefundPolicy() {
    return (
        <section>
            <div className="policy-page-container">
                <div className="policy-heading">
                    <h1 className="heading">Refund Policy</h1>
                </div>
                <div className="policy-body">
                    <p>
                        The refund policy of the academy stipulates that once the enrollment fee
                        is paid, it becomes non-refundable. Training Fee is refundable under the
                        circumstances of health emergency, unfortunate event of a bereavement
                        within the family, students may request for a refund. Refund of the
                        Training fee will be considered only within the first week from the
                        program start date. After the first week, refunds for the Training fee
                        will not be available.
                    </p>
                    <p>
                        You acknowledge that the program fee paid by you is non-transferable and
                        non-refundable.
                    </p>
                    <p>
                        You acknowledge that we are under no obligation to refund any fees and
                        applicable charges paid by you, in full or partially, under no
                        circumstances, including for modifying and extending the duration of the
                        program, change in the commencement date of the program, your failure to
                        attend or participate in the program, modification of the structure or
                        content of the program.
                    </p>
                </div>
            </div>

        </section>
    )
}
