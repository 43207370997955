import { db } from "../../.."
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { Input } from "@mui/material";
import { useFormik } from 'formik';
import { TextField, Button } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";


export default function ManageEmails() {

    const [loaderText, setLoaderText] = useState('')
    const [emailCount, setEmailCount] = useState(0)

    const formik = useFormik({
        initialValues: {
            email: ''

        },
        onSubmit: (values) => {
            const mailsArray = values.email.split('\n');
            const studentMailsFiltered = mailsArray.filter(v => v !== '');

            batchAddEmails(studentMailsFiltered)
        },
    });

    const formikAdmin = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: (values) => {
            const mailsArray = values.email.split('\n');
            const adminMailsFiltered = mailsArray.filter(v => v !== '');

            batchAddEmailsAdmin(adminMailsFiltered)
        },
    });

    async function batchAddEmailsAdmin(adminMails) {
        adminMails.forEach(async (mail) => {
            await addEmailAdmin(mail)
        });
        alert('Emails Uploaded')
    }



    async function batchAddEmails(studentMails) {
        studentMails.forEach(async (mail) => {
            await addEmail(mail)
        });
        alert('Emails Uploaded')
    }

    async function addEmail(studentMail) {

        await addDoc(collection(db, "Students"), {
            email: studentMail,
            joinDate: serverTimestamp(),
            access: true

        }).then(() => {

            console.log('Email Updated')
            return true

        }).catch((e) => {
            alert('Cannot add Mails, Please Try Again')
        });

    }

    async function addEmailAdmin(adminMail) {

        await addDoc(collection(db, "Admins"), {
            email: adminMail,
            joinDate: serverTimestamp(),
            access: true

        }).then(() => {

            console.log('Admin Email Updated')
            return true

        }).catch((e) => {
            alert('Cannot add Mails, Please Try Again')
        });

    }

    async function deleteEmail() {

        await addDoc(collection(db, "Students"), {
            email: getAuth().currentUser.email,
            joinDate: serverTimestamp()
        }).then(() => {

        }).catch(() => {

        });
    }

    return (
        <>

            <div className="flex justify-evenly bg-slate-200 py-4">
                <Link to='manage-mail' className="text-lg text-black hover:underline cursor-pointer ">See/Edit Mails</Link>
            </div>
            <div className="px-10 grid grid-cols-2">
                <div>
                    <h1 className="text-center text-2xl font-semibold py-6">Add Student Emails</h1>

                    <form onSubmit={formik.handleSubmit}>

                        <TextField className='w-full' multiline rows={15} pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$' required label="Student Email" id='email' name='email' variant="outlined" onChange={formik.handleChange}
                            value={formik.values.email} />
                        <br />
                        <br />

                        <Button variant="contained" type='submit'>Add Email</Button>

                        <h1 className="text-xl">{loaderText}</h1>

                    </form>
                </div>
                <div>
                    <h1 className="text-center text-2xl font-semibold py-6">Add Admin Emails</h1>

                    <form onSubmit={formikAdmin.handleSubmit}>

                        <TextField className='w-full' multiline rows={15} pattern='^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$' required label="Student Email" id='email' name='email' variant="outlined" onChange={formikAdmin.handleChange}
                            value={formikAdmin.values.email} />
                        <br />
                        <br />

                        <Button variant="contained" type='submit'>Add Email</Button>

                        <h1 className="text-xl">{loaderText}</h1>

                    </form>
                </div>
            </div>

        </>
    )
}