// src/components/Payment.js
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

const RazorPayment = () => {
  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      email: "",
      amount: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      number: Yup.string()
        .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      amount: Yup.number("Please Enter A Number").required("Required"),
    }),
    onSubmit: async (values) => {
      
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL}/razorpay/create-order`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const order = await response.json();
        

        var options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
          amount: values.amount*100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: "INR",
          name: "Hashpro Academy",
          description: "Test Transaction",
          image: "https://firebasestorage.googleapis.com/v0/b/graphy/o/global%2Flogos%2FHASHPRO%20(R)%201.png?alt=media&token=6abc9dc0-508f-477d-9f2c-40ce2a59acc0",
          order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: async function (response) {
            const body = { ...response };
            const validateResponse = await fetch(
              `${process.env.REACT_APP_SERVER_URL}/razorpay/validatePayment`,
              {
                method: "POST",
                body: JSON.stringify(body),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            const jsonRes = await validateResponse.json();
            console.log(jsonRes);
          },
          prefill: {
            name: values.name,
            email: values.email,
            contact: values.number,
          },
          notes: {
            address: "Razorpay Corporate Office",
          },

          theme: {
            color: "#796711",
          },
        };

        var rzp1 = new window.Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          // alert(response.error.code);
          // alert(response.error.description);
          alert(response.error.source);
          alert(response.error.step);
          alert(response.error.reason);
          alert(response.error.metadata.order_id);
          alert(response.error.metadata.payment_id);
        });

        rzp1.open();
      } catch (error) {
        console.error("Error creating order:", error.message);
      }
    },
  });

  return (
    <div className="pt-32">
      <link rel="stylesheet" href="/global/css/global.css" />
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n .fee-form-container {\n            padding-top: 70px;\n            padding-bottom: 70px;\n\n        }\n\n        table {\n            margin: 10px;\n            border-spacing: 0 1em;\n            border-collapse: separate;\n\n        }\n    ",
        }}
      />

      <div className="fee-form-container">
        <form method="post" onSubmit={formik.handleSubmit} autocomplete="on">
          <h1 className="text-3xl font-medium">Pay Fee</h1>
          <table width="40%" height={100}>
            <tbody>
              <tr>
                <td>Name :</td>
                <td>
                  <input
                    type="text"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    required
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div>{formik.errors.name}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>Phone No :</td>
                <td>
                  <input
                    type="number"
                    name="number"
                    
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number}
                    required
                  />
                  {formik.touched.number && formik.errors.number ? (
                    <div>{formik.errors.number}</div>
                  ) : null}
                </td>
              </tr>

              <tr>
                <td>Email :</td>
                <td>
                  <input
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    required
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div>{formik.errors.email}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td>
                  <p>Amount :</p>
                </td>
                <td>
                  <input
                    type="number"
                    name="amount"
                    onChange={formik.handleChange}
                    value={formik.values.amount}
                  />
                  {formik.touched.amount && formik.errors.amount ? (
                    <div>{formik.errors.amount}</div>
                  ) : null}
                </td>
              </tr>
              <tr>
                <td />
                <td>
                  <button type="submit" className="black">
                    Proceed To Payment
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
};

export default RazorPayment;
