import PlacementInputs from "../Components/PlacementInputs";
import { useParams, useLocation } from 'react-router-dom';

export default function EditPlacement() {

    const location = useLocation();
    const placementData = location.state.placementData;

    return (
        <>
            
            <h1 className="text-center text-2xl font-semibold py-6">Edit Placements</h1>
            <PlacementInputs data={placementData} type='edit' />

        </>
    )
}