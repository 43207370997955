export default function HATThankYou() {
    return (
        <>
            <section className="relative bg-cross-grid h-[100vh] w-[100vw]">
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full">
                    <h2 className="text-hashpro text-7xl font-extrabold text-center">Thank You For Submitting HAT</h2>
                    <p className=" text-2xl text-center mt-6">Your result shall be communicated by Hashpro team.</p>
                    <a href="/">
                    <button className='mt-8 mx-auto'>Go To Home Page</button>
                </a>
                </div>
               
            </section>
        </>
    )
}