

import './about.min.css'
import NumberCounter from '../../Global_Components/NumberCounter';
import NumberCounterDecimal from '../../Global_Components/NumberCounterDecimal';
import { Helmet } from 'react-helmet';
export default function About() {

    return (
        <>
            <Helmet>
                <title>{'About'}</title>
                <meta name="description" content="About" />
                <meta property="og:title" content="Your Open Graph Title" />
                <meta property="og:description" content="Your Open Graph Description" />
                <meta property="og:image" content="URL to Your Image" />
                <meta property="og:url" content="URL to Your Web Page" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Your Site Name" />
                <meta property="og:locale" content="en_US" />
                <meta name="twitter:title" content="Your Twitter Title" />
                <meta name="twitter:description" content="Your Twitter Description" />
                <meta name="twitter:image" content="URL to Your Image" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>
            <div className="about-page">
                <section className="hero">
                    <h1 className="text-center text-white text-4xl leading-tight">Bridging the Gap, Building the Future</h1>
                    <p className="subheading">
                        We're a young team with a big vision. We saw a gap in the market and
                        decided to fill it with innovative solutions and unwavering passion.
                    </p>
                    {/* <button className="yellow">button button</button> */}
                    <div className="credibility mt-12">
                        <div className="first">
                            <div className="card">
                                <img
                                    src="/global/images/hero-cards/google-reviews.png"
                                    alt=""
                                />
                                <h3>Rated <NumberCounterDecimal targetNumber={4.5} />/5</h3>
                                <p>Google Reviews</p>
                            </div>
                            <div className="card">
                                <img
                                    src="/global/images/hero-cards/success-stories.png"
                                    alt=""
                                />
                                <h3><NumberCounter targetNumber={400} />+</h3>
                                <p>Success Stories</p>
                            </div>
                            <div className="card">
                                <img
                                    src="/global/images/hero-cards/community.png"
                                    alt=""
                                />
                                <h3>
                                    <NumberCounter targetNumber={10} />L+</h3>
                                <p>Community</p>
                            </div>
                        </div>
                        {/* <div className="second">
                        <div className="card">
                            <img
                                src="/global/images/hero-cards/our-programs.png"
                                alt=""
                            />
                            <div>
                                <p>Our Programs</p>
                                <h3 style={{ fontSize: '1.6rem' }}>4 </h3>
                            </div>
                        </div>
                        <div className="card">
                            <img
                                src="/global/images/hero-cards/csat.png"
                                alt=""
                            />
                            <div>
                                <p>CSAT</p>
                                <h3>4.5 / 5</h3>
                            </div>
                        </div>
                        <div className="card">
                            <img
                                src="/global/images/hero-cards/cohort.png"
                                alt=""
                            />
                            <div>
                                <p>Cohorts</p>
                                <h3>50+</h3>
                            </div>
                        </div>
                    </div> */}
                    </div>
                </section>

                <section className="hashpro-way">
                    <div className="head">
                        <h2 className="heading"></h2>
                        <p className="subheading">

                        </p>
                    </div>
                    <div className="grid inv">
                        <div className="banner">
                            <img
                                src="/global/about/images/we-planning.webp"
                                alt=""
                            />
                        </div>
                        <div className="text">
                            <h3>How We Are Planning</h3>
                            <p>
                                We plan to empower individuals through our professional and
                                business-focused courses. Whether preparing for a job or
                                entrepreneurship, Hashpro is committed to enabling successful careers.
                                Our strategy revolves around crafting dynamic curriculum that evolves
                                with industry advancements, ensuring our learners stay ahead.
                            </p>
                        </div>
                    </div>
                    <div className="grid ">
                        <div className="text">
                            <h3>We Are Strong in Values</h3>
                            <p>
                                At Hashpro, our unique selling point is our unwavering care. We deeply
                                value our students and the transformative journey they embark on with
                                us. Our sole focus is to deliver exceptional value to our students. We
                                go the extra mile to ensure their satisfaction and that they fully
                                benefit from the value we provide.
                            </p>
                        </div>
                        <div className="banner">
                            <img
                                src="/global/about/images/strong-in-values.webp"
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="grid inv">
                        <div className="banner">
                            <img
                                src="/global/about/images/our-inspiration.webp"
                                alt=""
                            />
                        </div>
                        <div className="text">
                            <h3>Our Inspiration</h3>
                            <p>
                                College life is vastly different from the real world, which is rich with
                                opportunities yet often lacks preparation from traditional education. At
                                Hashpro, we are driven by the vision to bridge this gap by transforming
                                individuals personally and professionally, equipping them with the
                                confidence to thrive in the real world.
                            </p>
                        </div>
                    </div>
                </section>
                <section style={{ paddingTop: '0px ' }} className="cta-container ">
                    <div className="cta shadow-[2px_2px_white]" style={{ scale: 1 }}>
                        <div className="left">
                            <p className="heading-cta">
                                Discover your journey of <span> limitless opportunities</span> with
                                HashPro
                            </p>
                        </div>
                        <div className="right">
                            <a href="#courses-a">
                                <button className="black">Explore Programs</button>
                            </a>
                        </div>
                    </div>
                </section>
                <section style={{ backgroundImage: 'linear-gradient(to bottom, #ffffffbb,#ffffffbb),url("/global/images/white-elevated-bg.jpg")' }} className="hiring">
                    <h1 className="heading">Build Your Future with Us</h1>
                    <p className="subheading font-[700_!important]">
                        Join a team that's changing the game. We're looking for passionate
                        individuals to help us shape the future.
                    </p>
                    <div className="jobs">
                        <div className="card">
                            <div>
                                <h2 className="job-title">Business Development Associate</h2>
                                <p className="time">Posted on 08-04-2024</p>
                            </div>
                            <div className='h-fit my-auto'>
                                <a href="/careers/business-development/">
                                    <button className="yellow">Apply Now</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>


    )
}