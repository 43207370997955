import { getAuth } from "firebase/auth";
import { db } from "../../..";
import { collection, getDocs, query } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";





export async function fetchStudentMails() {

    const querySnapshot = await getDocs(collection(db, "Students"));
    let emailArray = [];


    querySnapshot.forEach((doc) => {
        const data = doc.data()
        data.id = doc.id
        emailArray.push(data)

    });

    // console.log(JSON.stringify(querySnapshot))
    // return JSON.stringify(querySnapshot)
    return emailArray
}


export async function deleteEmail(id) {

    try {
        await deleteDoc(doc(db, "Students", id));
        return true
    } catch (e) {
        return false

    }

}