const url = "https://www.hashproacademy.com/placements/api/uploadPlacement.php";
const editUrl = "https://www.hashproacademy.com/placements/api/editPlacement.php";
const deleteUrl = "https://www.hashproacademy.com/placements/api/deletePlacement.php";


export function fetchPlacements() {
    return new Promise((resolve, reject) => {

        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true); // Adjust the URL to your PHP script

        xhr.onload = () => {
            if (xhr.status === 200) {
                const placements = JSON.parse(xhr.responseText);
                resolve(JSON.parse(xhr.responseText));


            } else {
                console.error('fetchPlacement script failed', xhr.status);
                // reject(new Error(`Request failed with status ${xhr.status}`));

            }
        };

        xhr.onerror = () => {
            console.error('Request failed');
            // reject(new Error(`Request failed with status ${xhr.status}`));

        };

        xhr.send();
    });

};


export function editPlacement(formData) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', editUrl, true);

        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(true);

            } else {
                console.error('fetchPlacement script failed', xhr.status);
                reject(new Error(`Request failed with status ${xhr.status}`));
            }
        };

        xhr.onerror = (e) => {
            console.error('Request failed', e);
            // reject(new Error(`Request failed with status ${xhr.status}`));
        };

        xhr.send(formData);
    });

}

export function deletePlacement(id) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', deleteUrl, true);

        xhr.onload = () => {
            if (xhr.status === 200) {
                resolve(true);
                console.log(xhr.responseText)
                console.log(true)

            } else {
                console.error('fetchPlacement script failed', xhr.status);
                reject(new Error(`Request failed with status ${xhr.status}`));
            }
        };

        xhr.onerror = (e) => {
            console.error('Request failed', e);
            reject(new Error(`Request failed with status ${xhr.status}`));
        };
        const idForm = new FormData()
        idForm.append('id',id)

        xhr.send(idForm);
    });

}