import Mcq from "./Mcq"

export default function ParagraphQuestion({ paragraph, mcqs }) {

    return (

        <div className="grid grid-cols-2 gap-6">
            <div className="max-h-[calc(100vh-10rem)] overflow-y-scroll pr-4">
                {

                    paragraph.map((para) => {
                        return (
                            <p className="mb-3 text-md font-medium leading-loose " dangerouslySetInnerHTML={{__html: para}}></p>
                        )
                    })
                }
            </div>
            <div className="max-h-[calc(100vh-10rem)] overflow-y-scroll">
                {
                    mcqs.map((mcq) => {

                        return (

                            <Mcq mcq={mcq} />

                        )
                    })
                }
            </div>
        </div>

    )

}